:root {
  --primary-color: #00e2ed;
  --primary-color2: #02a3ac;
  --secondary-color: #9875f6;
  --secondary2-color: #e7ddfd;
  --theme-dark: #0c0a0c;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@font-face {
  font-family: "robotobold";
  src: url("../fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../fonts/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "aladinregular";
  src: url("../fonts/aladin-regular-webfont.woff2") format("woff2"),
    url("../fonts/aladin-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "robotoregular";
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.relative,
body,
html {
  position: relative;
}

body,
ul {
  margin: 0;
  padding: 0;
}

html {
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-weight: normal;
  line-height: 1.6 !important;
  font-size: 18px;
  font-family: "robotoregular";
  color: var(--bs-white);
  background: var(--theme-dark);
  position: relative;
  padding-top: 70px;
  background-size: cover;
}

html {
  height: 100%;
}

ul {
  list-style: none;
}

/* select option {
  color: #000 !important;
} */

.btn,
a,
button {
  display: inline-block;
  outline: 0 !important;
  box-shadow: none !important;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.btn,
a,
a:focus,
a:hover,
button {
  text-decoration: none;
  outline-style: none;
}

.btn {
  padding: 12px 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 10px;
  font-size: 14px;
}

.btn.btn_samll {
  padding: 10px 10px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 2px;
  border-radius: 10px;
  font-size: 60%;
  width: 140px;
}

.btn.btn_samll.btn_icon {
  width: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.1;
  font-family: "robotobold";
}

.p-v-60 {
  padding: 60px 0;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 49px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

.p-v-50 {
  padding: 50px 0;
}

.tshadow {
  text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.5);
}

.ml-10 {
  margin-left: 10px;
}

.btn-light:hover {
  background: var(--theme-dark);
  color: var(--bs-white);
  border-color: var(--theme-dark);
}

.logo {
  display: block;
}

.logo a {
  display: inline-block;
  text-align: center;
}
.logo img {
  width: 100px;
}

header {
  background: var(--theme-dark);
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 99;
  border-bottom: rgba(255, 255, 255, 0.2) 1px solid;
}
.EZDrawer .EZDrawer__container {
  position: fixed;
  visibility: hidden;
  background: #1e1e1e !important;
  transition: all;
  box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.8) !important;
  z-index: 999 !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 30px;
}
.infos li,
header nav ul li {
  width: auto;
  float: left;
}

header nav ul li a {
  padding: 0 15px;
  color: var(--bs-white);
  font-family: "robotoregular";
  position: relative;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
header nav ul li a i {
  color: var(--primary-color);
}

.infos li a:hover,
header nav ul li a:hover,
header nav ul li a.active {
  color: var(--secondary-color);
}
header {
  background: var(--theme-dark);
  padding: 15px 40px 15px 0;
}
.hamburger .line {
  width: 20px;
  height: 1px;
  background-color: var(--bs-white);
  display: block;
  margin: 8px auto;
  transition: all 0.3s ease-in-out;
}
.noBg {
  background: transparent !important;
}

.hamburger:hover {
  cursor: pointer;
}

/* ONE */
.hamburger {
  display: none;
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 16px;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-7px) rotate(316deg);
  -ms-transform: translateY(-7px) rotate(316deg);
  -o-transform: translateY(-7px) rotate(316deg);
  transform: translateY(-7px) rotate(316deg);
}

.infos li a {
  padding: 0 15px;
  color: var(--bs-white);
  font-size: 20px;
}

nav ul li a::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--secondary-color);
  content: "";
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
}

nav ul li a:hover::after,
nav ul li a.active::after {
  opacity: 1;
  visibility: visible;
}

.owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: 50%;
  left: 0;
  width: 90%;
  text-align: left;
  margin: 0 auto;
  right: 0;
  height: 1px;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.react-multiple-carousel__arrow {
  margin: 0 !important;
  padding: 0 !important;
  width: 50px !important;
  height: 50px !important;
  z-index: 5;
  border: var(--bs-white) 1px solid !important;
  background: var(--primary-color) !important;
  border-radius: 50% !important;
  font-size: 18px !important;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px) !important;
}
.react-multiple-carousel__arrow:hover {
  border: var(--bs-white) 1px solid !important;
  color: var(--bs-white);
}
.react-multiple-carousel__arrow:hover::before {
  color: var(--bs-white);
}
.movie_content span .btn {
  margin: 0 2px;
}
.movieInfos li {
  font-size: 60%;
  padding-right: 10px;
}
.react-multi-carousel-dot button {
  width: 40px !important;
  height: 4px !important;
  border-radius: 50%;
  padding: 0 !important;
  border-color: var(--bs-white) !important;
  border-radius: 0 !important;
  opacity: 0.5 !important;
}
.react-multi-carousel-dot--active button {
  border-color: var(--bs-white) !important;
  opacity: 1 !important;
}
.video_slier .react-multi-carousel-dot-list {
  display: flex !important;
  bottom: 22px !important;
}

.video_slier .item {
  padding: 30px 0 80px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--bs-light);
  opacity: 1;
}
.home_slider {
  position: relative;
}
.home_slider img {
  height: 600px;
}

.banner_caption {
  position: absolute;
  top: 50%;
  /* left: 10%; */
  width: 100%;
  /* max-width: 600px; */
  text-align: center;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.caption_info {
  margin: 20px 0;
}

.caption_info li {
  padding-right: 2px;
}

.caption_info i {
  font-size: 25px;
  list-style: 0.8;
  position: relative;
  top: 3px;
}

.caption_info p {
  font-size: 25px;
  list-style: 0.8;
  position: relative;
  top: 3px;
}

.badge {
  font-size: 16px;
  padding: 6px 10px;
  color: var(--bs-white);
  font-weight: normal;
}

.badge.bg-light {
  background: rgba(255, 255, 255, 0.2) !important;
}

.site_text_bold {
  font-family: "robotobold";
}

.site_text {
  font-family: "robotoregular";
}

.rating_tags {
  margin: 40px 0;
}

.rating_tags li a {
  color: var(--primary-color);
  padding: 0 20px;
  position: relative;
}

.rating_tags li a::after {
  position: absolute;
  background: var(--primary-color);
  width: 1px;
  height: 15px;
  right: 0;
  content: "";
  top: 10px;
}

.rating_tags li:first-child a {
  color: var(--primary-color);
  padding: 0;
  line-height: 0.5;
}

.rating_tags li:first-child a::after,
.rating_tags li:last-child a::after {
  display: none;
}

.rating_tags li a span {
  color: var(--bs-light);
}

a.text-light {
  color: var(--secondary2-color) !important;
}
a.text-light:hover {
  color: var(--secondary-color) !important;
}
a.text-white {
  color: #fff !important;
}
a.text-white:hover {
  color: var(--secondary-color) !important;
}
.text-warning {
  color: var(--primary-color) !important;
}
.btn-outline-warning {
  color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-outline-warning:hover {
  color: #000;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.banner_btn a {
  font-size: 18px;
  background: var(--bs-dark);
  color: var(--bs-white) !important;
  padding: 10px 25px;
  border-radius: 6px;
  display: block !important;
}

.banner_btn a:hover {
  background: var(--secondary-color);
  color: var(--bs-white) !important;
}

.length_big {
  max-width: 300px;
  width: 100%;
}

.latest_release {
  background: var(--bs-secondary);
  position: relative;
  z-index: 1;
  /* padding-top: 50px; */
}

.latest_release::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.23) 0,
    rgba(0, 0, 0, 1) 100%
  ); */
  content: "";
  z-index: -1;
}

.main_img {
  height: 270px;
  object-fit: cover;
}

.react-multi-carousel-list {
  padding-left: 2em;
}

.latest_release .row {
  min-height: 470px;
}

.site_heading {
  color: var(--bs-white);
  text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.8);
}

.site_heading span {
  color: var(--primary-color);
}

.latest_release .item img {
  width: 90px;
  height: 300px;
  object-fit: cover;
}

.latest_release .react-multi-carousel-item {
  padding: 0 10px;
  z-index: 1;
}
.latest_release .react-multi-carousel-item > div {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
  border: var(--theme-dark) 1px solid;
  border-radius: 6px;
}
.latest_release .react-multi-carousel-item > div .defaultPoster {
  transition: 0.3s ease-in-out;
  display: block;
  float: left;
  box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.2);
}
.latest_release .react-multi-carousel-item:hover {
  z-index: 9;
}
/* .latest_release .react-multi-carousel-item > div:hover {
  -moz-transform: scale(1.25, 1.25);
  -webkit-transform: scale(1.25, 1.25);
  -o-transform: scale(1.25, 1.25);
  -ms-transform: scale(1.25, 1.25);
  transform: scale(1.25, 1.25);

  border-color: var(--bs-light);
} */
.latest_release .react-multi-carousel-item > div .movie_content {
  width: 100%;
  float: left;
}
.latest_release
  .react-multi-carousel-item
  > div
  .movie_content
  .fa-solid.fa-play {
  text-indent: 2px;
}
.latest_release .react-multi-carousel-item > div .movie_content .bi {
  text-indent: 2px;
}

/* .latest_release .item:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.77) 0,
    rgba(0, 0, 0, 0) 100%
  );
  content: "";
  z-index: 0;
} */

.latest_release .react-multi-carousel-item > div:hover .item:before {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.77) 100%
  );
}

.latest_release .react-multi-carousel-track {
  padding: 30px 0 0;
}

.movie_content {
  width: 100%;
  padding: 15px 15px;
  border-radius: 0 0 6px 6px;
  text-align: left;
  background: var(--bs-dark);
}

.latest_release .react-multi-carousel-item > div:hover .movie_content {
  opacity: 1;
  visibility: visible;
}
.react-multi-carousel-dot-list {
  display: none !important;
}

.movie_content .caption_info,
.movie_content .caption_info .badge {
  font-size: 48%;
}

.movie_content .site_text {
  font-size: 55%;
  margin: 0;
}

.movie_content .caption_info i {
  font-size: 70%;
  top: 0;
}

.movie_content .caption_info {
  margin: 10px 0;
}

.stylist_hading {
  font-family: "aladinregular";
}

.stylist_hading span {
  color: var(--secondary2-color);
}

.btn-secondary2 {
  background: var(--secondary-color);
  color: var(--bs-white) !important;
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.8);
}

.btn-secondary2:hover {
  background: var(--primary-color2);
}

.downloads {
  padding: 100px 0;
  background: url(../images/download-bg.jpg) var(--theme-dark) no-repeat top
    left;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.downloads::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(193, 125, 0, 0.41) 0,
    rgba(233, 132, 12, 0.01) 54%,
    rgba(232, 132, 12, 0) 55%,
    rgba(193, 125, 0, 0.41) 100%
  );
  content: "";
  z-index: -1;
  opacity: 0.2;
}

.downloads .btn {
  margin-left: 10px;
}

.downloads .btn i {
  font-size: 18px;
}

/* .owl-carousel.owl-drag .owl-item a{
	display: block;
} */
.top_week {
  background: var(--theme-dark);
}

.latest_release {
  background: #15171a no-repeat top left;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding-top: 50px;
  overflow: hidden;
}
.movieWeek {
  overflow: hidden;
}

.top_week .react-multi-carousel-item img {
  border-radius: 15px;
}

.top_week .react-multi-carousel-item > div {
  padding: 0 10px 70px;
  border-radius: 15px;
  overflow: hidden;
}

.top_week .owl-theme .owl-nav,
.video_slier .owl-theme .owl-nav {
  width: 116%;
  left: -8%;
}

.week_content {
  position: absolute;
  bottom: 0;
  padding: 5% 0 0;
  left: 0;
  width: 100%;
  border-right: var(--bs-white) 1px solid;
}

.week_content h3 {
  font-size: 7vw;
  -webkit-text-stroke-color: rgba(255, 255, 2555, 0.6);
  -webkit-text-stroke-width: 1px;
  font-family: "robotobold";
  line-height: 1;
  color: transparent;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.top_week .owl-carousel.owl-drag .owl-item {
  padding-bottom: 50px;
}

.top_week.best_hero a,
.top_week.original a {
  color: var(--bs-white);
  display: block;
}

.top_week.best_hero a:hover,
.top_week.original a:hover {
  color: var(--primary-color);
}

.top_week.best_hero h4,
.top_week.original h4 {
  padding-top: 50px;
  font-family: "aladinregular";
}
.top_week .react-multi-carousel-item > div:hover h3 {
  color: var(--primary-color);
  -webkit-text-stroke-color: var(--primary-color);
}

.n_geographic {
  margin: 50px 0 0;
  background: url(../images/creatorbg.jpg) var(--theme-dark) no-repeat left;
  background-size: cover;
  height: 80vh;
  z-index: 1;
  position: relative;
}

.n_geographic::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.24) 0,
    rgba(0, 0, 0, 0.24) 1%,
    rgba(0, 0, 0, 0.92) 100%
  );
  content: "";
  z-index: 1;
}

.n_geographic .col-6 {
  position: relative;
  z-index: 2;
}

.top_week.original {
  position: relative;
  /* margin-top: -100px; */
  z-index: 1;
  margin-bottom: 0;
  overflow: hidden;
}

.video_slier {
  padding: 50px 0;
  background: url(../images/bg-6.jpg) var(--theme-dark) no-repeat top left;
  background-size: cover;
  z-index: 1;
  position: relative;
}

.video_slier::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.97) 0,
    rgba(0, 0, 0, 0.85) 78%,
    rgba(0, 0, 0, 0.72) 100%
  );
  content: "";
  z-index: -1;
}

.video_slier .owl-item {
  padding: 30px 30px 80px;
}

.video_slier video {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.75);
}

.brands {
  background: url(../images/brand.jpg) var(--theme-dark) no-repeat top left;
  background-size: cover;
  padding: 50px 0;
}
.brands .react-multiple-carousel__arrow--left,
.brands .react-multiple-carousel__arrow--right {
  display: none;
}

.like_together {
  padding: 50px 0;
  background: url(../images/bg-7.jpg) var(--theme-dark) no-repeat top left;
  background-size: cover;
  z-index: 1;
  position: relative;
}

.like_together::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(193, 125, 0, 0.41) 0,
    rgba(233, 132, 12, 0.01) 54%,
    rgba(232, 132, 12, 0) 55%,
    rgba(193, 125, 0, 0.41) 100%
  );
  content: "";
  z-index: -1;
  opacity: 0.2;
}

footer {
  padding: 50px 0 10px;
  /* margin-top: 50px; */
  background: url(../images/footer-bg.jpg) var(--theme-dark) no-repeat top left;
  background-size: cover;
  border-top: #333 1px solid;
}

.footer_head {
  font-size: 16px;
  font-family: "robotoregular";
  margin-bottom: 30px;
  color: var(--bs-white);
  font-weight: bold;
}

.footer_link,
.footer_text {
  font-size: 14px;
}

.footer_link {
  margin-bottom: 15px;
}

.footer_link a,
.footer_text a {
  color: var(--bs-white);
}

.footer_link a:hover {
  color: var(--primary-color);
}

.footer_social a:hover,
.footer_text a:hover {
  color: var(--bs-white);
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.footer_social {
  display: inline-block;
}

.footer_social a {
  color: var(--bs-white);
  font-size: 16px;
  padding-right: 0;
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  border: #fff 1px solid;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
  margin-top: 10px;
}
.footer_text {
  margin-bottom: 0;
  font-size: 85%;
}
a.footer_text {
  margin-left: 10px;
  color: #fff;
}

footer .btn-sm {
  font-size: 12px;
  padding: 0 10px;
  height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 6px 6px 0;
}

.innerBanner,
.movie_list {
  padding: 60px 0 50px;
}
.innerBanner_main_wrap {
  position: relative;
}
.innerBanner_img_wrap {
  overflow: hidden;
  border-radius: 30px;
  -webkit-box-shadow: 1px 2px 52px -12px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 1px 2px 52px -12px rgba(255, 255, 255, 0.5);
  box-shadow: 1px 2px 52px -12px rgba(255, 255, 255, 0.5);
  z-index: 0;
  position: relative;
  height: 300px;
}

.users {
  margin-top: -100px;
}
.users .main_User {
  display: inline-block;
  width: 200px;
  position: relative;
}
.users .main_User img {
  width: 100%;
  height: 200px;
  border-radius: 50%;
  border: var(--primary-color) 1px solid;
  padding: 10px;
  object-fit: cover;
}
.mail {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  bottom: -25px;
  background: var(--theme-dark);
  text-align: center;
  line-height: 50px;
  color: var(--secondary2-color);
  border: var(--bs-light) 1px solid;
  font-size: 25px;
  -webkit-box-shadow: -1px 1px 0px 8px rgba(0, 0, 0, 1);
  -moz-box-shadow: -1px 1px 0px 8px rgba(0, 0, 0, 1);
  box-shadow: -1px 1px 0px 8px rgba(0, 0, 0, 1);
}
.user_heading {
  margin: 40px 0 20px;
  text-shadow: 2px 7px 12px rgba(251, 194, 35, 0.5);
}
.user_text {
  margin-bottom: 30px;
}
.joined {
  position: absolute;
  right: 0;
  top: 40%;
  background: rgba(255, 255, 255, 0.15);
  padding: 8px 30px;
  color: var(--bs-white);
  font-size: 70%;
  border-radius: 15px;
  font-weight: 600;
}
.users .btn {
  min-width: 200px;
  margin: 0 5px;
}
.tab_main .nav-tabs {
  border-bottom: transparent;
}
.tab_main .nav-tabs li {
  padding: 0 60px 0 0;
}
.tab_main .nav-tabs .nav-link {
  color: var(--bs-white);
  font-family: "robotobold";
  background: transparent !important;
  border: transparent;
  position: relative;
  padding: 0 0 5px;
}
.tab_main .nav-tabs .nav-link::before {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  width: 100%;
  margin: 0 auto;
  height: 2px;
  background: var(--secondary-color);
  content: "";
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.tab_main .nav-tabs .nav-link:hover::before,
.tab_main .nav-tabs .nav-link.active::before {
  opacity: 1;
  visibility: visible;
}
.tab_main .nav-tabs .nav-link.active {
  color: var(--secondary-color);
}
.tab_heading {
  margin: 30px 0 20px;
  font-size: 25px;
}
.tab_heading span {
  display: inline-block;
  margin-right: 10px;
}
.tab-pane {
  padding-top: 25px;
}
.tab_text {
  margin-bottom: 20px;
}
.videos {
  border: var(--bs-white) 1px solid;
  border-radius: 15px;
  margin-bottom: 10px;
  margin: 15px 0;
}
.video_title {
  font-family: "aladinregular";
  font-size: 34px;
  margin-bottom: 10px;
}
/* .video_text {margin-bottom:30px;} */
.movies {
  margin-top: 30px;
}
.movies a {
  display: block;
  overflow: hidden;
  border: var(--bs-white) 1px solid;
  border-radius: 15px;
}
.movies a:hover {
  border-color: var(--primary-color);
  /* -webkit-box-shadow: 1px 2px 52px -12px rgba(255,255,255,0.8);
    -moz-box-shadow: 1px 2px 52px -12px rgba(255,255,255,0.8);
    box-shadow: 1px 2px 52px -12px rgba(255,255,255,0.8); */
}
.movies a img {
  transition: 0.3s ease-in-out;
  height: 400px;
  object-fit: cover;
}
.movies a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.accordion-item {
  background-color: transparent;
  border: 1px solid transparent;
}
.accordion-body {
  padding: 0 0 25px;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-white);
  background-color: var(--secondary-color);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button {
  color: #212529;
  background-color: var(--bs-white);
}
.movie_details_Banner {
  /* background: url(../images/bg-8.jpg) var(--theme-dark) no-repeat top left; */
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 0 0 0px;
}
.movie_details_Banner::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 28%,
    rgba(0, 0, 0, 0.14) 100%
  );
  content: "";
  z-index: -1;
}
.movie_banner_pic {
  flex: 0 0 360px;
  padding-right: 30px;
}
.ratings {
  font-size: 24px;
  display: inline-block;
  padding-right: 30px;
  font-weight: 600;
}
.movieListTable img {
  height: 60px;
  object-fit: contain;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ratings a,
.votes a {
  color: var(--bs-light);
}
.ratings a i {
  color: var(--primary-color);
}
.movie_details_Banner .site_text {
  font-size: 90%;
}
.movie_details_Banner .site_text {
  margin: 15px 0 0;
}
.movie_details_Banner .caption_info {
  margin: 5px;
}
.ratingBtn {
  font-size: 14px;
  background: rgba(0, 226, 237, 0.15);
  color: var(--bs-light) !important;
  display: inline-block;
  border-radius: 15px;
  padding: 10px 8px 10px 8px;
  font-weight: 600;
}
.ratingBtn span {
  float: left;
  padding: 0 10px;
}
.ratingBtn .btn {
  font-size: 75%;
  padding: 8px;
}
.about_part {
  padding: 50px 0;
}
.cast .item a {
  display: block;
  text-align: center;
  color: var(--bs-white);
}
.cast_img {
  border-radius: 50%;
  width: 150px !important;
  height: 150px;
  object-fit: cover;
  margin: 0 auto;
}
.cast_text {
  max-width: 100px;
  width: 100%;
  margin: 20px auto 0;
  line-height: 1.3;
  font-size: 80%;
}
.cast .owl-theme .owl-nav {
  left: -5%;
  width: 110%;
  top: 45%;
}
.top_week.original .item div {
  padding: 15px;
}
.top_week.original.likes {
  position: relative;
  margin-top: 0px;
}
.top_week.original.likes .week_content {
  border-right: transparent;
}
.reviws .site_text {
  font-size: 80%;
}
.reviws_btn li {
  padding: 0 10px;
}
.reviws_btn .btn:hover .text-white {
  color: var(--theme-dark) !important;
}
.single_reviws {
  border: var(--bs-light) 1px solid;
  border-radius: 15px;
  padding: 20px 35px 10px;
  font-size: 80%;
  -webkit-box-shadow: 1px 2px 52px -12px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 1px 2px 52px -12px rgba(255, 255, 255, 0.5);
  box-shadow: 1px 2px 52px -12px rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background-color: var(--theme-dark);
  opacity: 0.8;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.2) 1px,
    #111 0,
    #111 50%
  );
}
.single_reviws .ratings {
  font-size: 14px;
  padding-right: 0;
}
.review_share a {
  color: var(--bs-light);
  padding: 0 5px;
}
.review_share a:hover {
  color: var(--primary-color);
}
.single_reviws:hover {
  border-color: var(--primary-color);
  -webkit-box-shadow: 1px 2px 52px -12px rgba(246, 190, 35, 0.5);
  -moz-box-shadow: 1px 2px 52px -12px rgba(246, 190, 35, 0.5);
  box-shadow: 1px 2px 52px -12px rgba(246, 190, 35, 0.5);
}
.tags {
  font-size: 80%;
}
.tags i {
  margin: 0 10px;
}
.loginPage {
  background-image: url("../images/login.jpg");

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 4em;
}
.ms_register_form p a {
  color: #cf1e22;
  text-transform: capitalize;
}
.loginPage::before {
  position: absolute;
  content: "";
  background: rgb(0 0 0 / 60%);
  width: 100%;
  height: 100%;
}
.Toastify__toast-container {
  z-index: 999999 !important;
}
.popup_forgot {
  margin-top: 1em;
}
.errorMess {
  color: red !important;
  margin: 0 !important;
  text-align: left !important;
  font-weight: 400 !important;
  font-size: 12px;
}
.login {
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 9rem;
  z-index: 9;
  position: relative;
  width: 100%;
}
.loginHeading {
  font-size: 30px;
  border-bottom: 2px solid #cf1e22;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 1em;
}
.signup {
  margin-top: 7rem;
}
.popup_forgot a {
  color: #cf1e22;
}
.hideCurrentModel {
  color: #cf1e22 !important;
}
.login label {
  color: #d9d9d9;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
}
.login input {
  height: 50px;
  background: transparent;
  border: 1px solid #8f090c;
  color: #fff;
}
.login input:focus {
  background: transparent;
  box-shadow: 0 0 0 0.25rem rgb(233 132 12 / 50%);
  border-color: #b4670a;
  color: #fff;
}
.login .form-group {
  margin-bottom: 15px;
}
.otpInput {
  margin: 50px 0;
}
.otpInput div input {
  width: 50px !important;
  height: 50px;
  font-size: 25px;
  border-color: #cd8200;
  border: 2px solid #cd8200;
}
.dassh {
  color: #cd8200;
  font-size: 25px;
}
.resend {
  color: #d5911c;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
.infos .btn-secondary2 {
  text-transform: capitalize;
  font-size: 15px;
  padding: 6px 9px;
}
.ms_register_form .otpInput {
  margin: 0px 0 21px;
}
/* 1-3-2024 */

.innerBanner_img_wrap img {
  height: 300px;
  object-fit: cover;
}
.editProfile .tab-pane {
  padding: 0 40px;
}
.editProfile.tab_main .nav-tabs .nav-link {
  width: 100%;
  text-align: left;
  padding: 10px 15px;
}
/* .editProfile.tab_main .nav-tabs .nav-link::before {
    position: absolute;
    left: calc(100% - 3px);
    top: 0;
    right: auto;
    width: 5px;
    margin: 0 auto;
    height: 93%;
    background: var(--secondary-color);
} */
.editProfile.tab_main .nav-tabs .nav-link i {
  width: 25px;
  text-align: center;
}
.editProfile .tab-content {
  min-height: 300px;
  background: #ffffff11;
  padding: 30px;
  border-radius: 0 30px 30px 0;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.75);
}
.form-control {
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: var(--bs-white) !important;
  background-color: #ffffff09 !important;
  background-clip: padding-box;
  border: 1px solid #333;
  border-radius: 6px;
  height: 45px;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.75);
}
.aboutVideo {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.tailreMove {
  position: relative;
  padding: 0;
}
/* .tailreMove::before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
} */
.movieDetails {
  position: absolute;
  top: 30px;
  left: 0;
  width: 90%;
  right: 0;
  margin: 0 auto;
}
.crosebtn {
  position: absolute;
  right: 0;
  display: block;
  width: 58px;
  color: #fcfcfc;
  font-size: 28px;
  top: 75px;
  z-index: 9999;
  cursor: pointer;
}
.form-floating > label {
  font-size: 0.8rem;
  font-weight: bold;
}
.editProfile.tab_main .nav-tabs li {
  padding: 0;
}
.form-select {
  color: #fff !important;
  background-color: #ffffff09 !important;
  border: none;
  border-bottom: 1px solid #fff;
  box-shadow: none !important;
  font-size: 0.8rem;
  border: 1px solid #333;
  border-radius: 6px;
  height: 45px;
}
.form-select option {
  color: #000;
}
.avatar-upload {
  position: relative;
  max-width: 162px;
  margin: 20px auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 2px;
  z-index: 1;
  top: 10px;
}
.text-lights {
  color: var(--secondary-color);
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  background: var(--secondary-color);
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  color: var(--bs-white);
  border-radius: 50%;
}
.avatar-upload .avatar-edit input + label:hover {
  background: var(--secondary2-color);
  border-color: var(--secondary2-color);
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--bs-white);
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 100%;
  height: 100%;
  position: relative;
  border: 6px solid #f8f8f88c;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

  border-radius: 50%;
}
.avatar-upload .avatar-preview > div {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.upload_dropZone {
  color: var(--bs-white);
  background-color: transparent;
  outline: 2px dashed var(--bs-white);
  outline-offset: 0;
  transition: outline-offset 0.2s ease-out, outline-color 0.3s ease-in-out,
    background-color 0.2s ease-out;
  padding: 20px 0;
  -webkit-transition: outline-offset 0.2s ease-out,
    outline-color 0.3s ease-in-out;
  -moz-transition: outline-offset 0.2s ease-out, outline-color 0.3s ease-in-out;
  -ms-transition: outline-offset 0.2s ease-out, outline-color 0.3s ease-in-out;
  -o-transition: outline-offset 0.2s ease-out, outline-color 0.3s ease-in-out;
}
.upload_dropZone.highlight {
  outline-offset: -4px;
  outline-color: var(--colorPrimaryNormal, #0576bd);
}
.upload_svg {
  fill: var(--colorPrimaryNormal, #0576bd);
}
.btn-upload {
  color: #fff;
  background-color: var(--colorPrimaryNormal);
}
.btn-upload:hover,
.btn-upload:focus {
  color: #fff;
  background-color: var(--colorPrimaryGlare);
}
.upload_img {
  width: calc(20% - (0.5rem / 4));
  object-fit: contain;
  border: #ddd 1px solid;
  -webkit-box-shadow: 1px 2px 26px -7px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 1px 2px 26px -7px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 1px 2px 26px -7px rgba(0, 0, 0, 0.15) !important;
}
.upload_dropZone i {
  font-size: 50px;
  color: #ddd;
}
.form-check-input:checked {
  background-color: var(--secondary2-color);
  border-color: var(--secondary2-color);
}
.editProfile label,
.form-check,
.upload_dropZone .btn {
  font-size: 90%;
}
.form-check-label {
  margin-right: 10px;
}
.form-check-label input {
  margin-right: 4px;
}
.logouts {
  width: 100%;
  background-color: #ff3531;
  border-radius: 5px;
  margin-top: 2em;
}
.loader {
  margin-top: 40px;
}
.videobutton {
  cursor: pointer;
}
.plusEpisod {
  color: #cd8200 !important;
  display: inline-block;
  padding-left: 7px;
  font-size: 20px;
  cursor: pointer;
}
.videoTitle {
  color: #000;
}
.videoDec {
  color: #000;
}
.movieName {
  color: #ffffff;
  text-transform: capitalize;
  margin-top: 10px;
  text-align: center;
}
.banner_heading {
  text-transform: capitalize;
}
.movieDetails .tab-content {
  background: transparent;
  padding: 0;
  box-shadow: none;
}
.movieDetails .tab-pane {
  padding: 0;
}
.form__img {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-color: #222028;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
}
.form__img input {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
.form__img label {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  margin: 0;
  font-weight: normal;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.4s ease;
}
.form__img label:hover {
  color: #fff;
}
.form__img img {
  position: absolute;
  z-index: 1;
  top: -100px;
  right: -100px;
  bottom: -100px;
  left: -100px;
  margin: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .form {
    padding: 30px;
    margin-bottom: 30px;
  }
  .form--profile {
    padding: 20px;
  }
  .form__cover {
    -ms-flex: 0 0 290px;
    flex: 0 0 290px;
    max-width: 290px;
  }
  .form__content {
    -ms-flex: 0 0 calc(100% - 290px);
    flex: 0 0 calc(100% - 290px);
    max-width: 100%;
  }
}
.form__input {
  margin-bottom: 20px;
  width: 100%;
  background-color: #222028;
  border: 2px solid transparent;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  color: #fff;
  border-radius: 6px;
  padding: 0 20px;
}

.form__textarea {
  border: 2px solid transparent;
  display: block;
  height: 136px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  color: #fff;
  padding: 15px 20px;
  resize: none;
  background-color: #222028;
  margin-bottom: 20px;
  border-radius: 6px;
}
.form__gallery {
  position: relative;
  width: 100%;
  height: 46px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 6px;
}
.form__gallery label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 46px;
  color: rgba(255, 255, 255, 0.7);
  padding: 0 50px 0 20px;
  background-color: #222028;
  margin: 0;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  transition: 0.4s ease;
}
.form__gallery input {
  position: absolute;
  left: -9999px;
  opacity: 0;
  z-index: 1;
}
.form__radio {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.form__radio li {
  position: relative;
  overflow: hidden;
  margin-right: 20px;
}
.form__radio span {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}
.form__radio input:checked + label:before {
  border-color: #f9ab00;
}
.form__radio label:before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 4px solid rgba(249, 171, 0, 0.6);
  background-color: transparent;
  border-radius: 50%;
  left: 0;
  top: 4px;
  transition: 0.4s ease;
}
.form__radio input:checked + label {
  color: #fff;
}
.form__video {
  position: relative;
  width: 100%;
  height: 46px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 6px;
  overflow: hidden;
}
.form__video label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 46px;
  color: rgba(255, 255, 255, 0.7);
  padding: 0 50px 0 20px;
  background-color: #222028;
  margin: 0;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  transition: 0.4s ease;
}

/* ? CONTROL VIDEO PLAYER */
.control_Container {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 1;
  /* display: flex;
  justify-content: space-between; */
}
.bottom__container {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.d-none {
  display: none !important;
}
.top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 20px;
}

.mid__container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon__btn {
  padding: 0 10px;
  color: #ffffff;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.volime {
  display: none;
}
.contIcon {
  display: flex;
  transition: all 0.5s;
}
.icon__btn svg {
  cursor: pointer;
}
.icon__btn:hover .volime {
  display: block;
}
.MuiSlider-root {
  height: 7px !important;
  padding: 0 !important;
}
.slider__container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.control__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner__controls {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 50%;
}
.genarelSettig button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.fulScreen {
  width: 24px;
}
/* span {
  color: #fff;
  font-size: 0.8rem;
  margin-left: 10px;
} */

.second__control {
  display: flex;
  align-items: center;
}
.genarelSettig {
  display: flex;
  align-items: center;
}
.genarelSettig svg {
  color: #fff;
  font-size: 34px;
}
.speedCheck {
  background: transparent;
  color: #fff;
  border: none;
}
.customDropForSpeed {
}
.watchIcon svg {
  cursor: pointer;
}
.drowpDown {
  position: absolute;
  background: #2f2e2e;
  width: 90px;
  color: #fff;
  bottom: 43px;
  padding: 5px;
  border-radius: 3px;
  right: 100px;
}
.speedValue {
  color: #fff;
  margin-right: 5px;
  font-weight: 700;
}
.drowpDown ul {
  padding: 0;
}
.drowpDown ul li {
  border-bottom: 1px solid #4d4d4d;
  list-style: none;
  cursor: pointer;
  display: block;
  margin-bottom: 7px;
  padding-bottom: 5px;
}
.video_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.player__wrapper {
  position: relative;
}

.player {
  object-fit: cover;
  padding: 0;
  margin: 0;
}
.MuiContainer-root {
  padding: 0 !important;
  max-width: inherit !important;
}
.select__control {
  border: none;
  background-color: #222028 !important;
  margin-bottom: 15px;
  border-width: inherit !important;
  min-height: 50px !important;
}
.select__menu {
  z-index: 999;
  position: absolute;
  background-color: #000 !important;
  display: block;
  opacity: 1;
}
.select__multi-value {
  background-color: #000 !important;
}
.select__multi-value__label {
  color: #fff !important;
}
.select_drop {
  color: #fff;
}
.select_drop option[value="free"] {
  color: #fff !important;
}
.cehckthum svg {
  color: #0cff0c;
  font-size: 28px;
}
.videoupload {
  height: 200px;
}
.progressBar svg {
  width: 70%;
}
.react-responsive-modal-modal {
  width: 600px !important;
}
.react-responsive-modal-modal .form-control {
  box-shadow: none !important;
  color: #000 !important;
}
.react-responsive-modal-modal .messag {
  height: 100px;
}
.react-simple-star-rating {
  margin-bottom: 15px;
}

.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.movieListTable td {
  width: 20%;
}
/* .movieListTable th {
  width: 20%;
} */
.uplaodimage {
  width: 100%;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #000;
  background-color: #ffffff09 !important;
  background-clip: padding-box;
  border: 1px solid #333;
  border-radius: 6px;
  height: 45px;
  margin-bottom: 15px;
}
.uplaodimage span {
  border-radius: 0 !important;
  padding: 10px 19px;
}
.creatorprofileImng img {
  height: 300px !important;
  object-fit: contain;
}

/*? kritika CSS */

/* home page banner */
.home_banner {
  position: relative;
  z-index: 1;
}
/* .home_banner::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  content: "";
} */
.banner_infos_listing {
  position: absolute;
  left: 0;
  bottom: 15%;
  width: 100%;
  padding: 30px 0;
  z-index: 1;
  max-width: 50%;
}
.btngroup span {
  padding: 0 15px 0 0;
}
.banner_btn.btn-light {
  background: var(--bs-white);
  color: var(--bs-dark) !important;
}
.bnner_play {
  border: 2px solid var(--primary-color);
}
.bannerList {
  margin: 50px 0 0;
}
/* .bannerList ul {margin:0 -5px; display: flex; flex-wrap: wrap; margin-top:15px}
.bannerList ul li {width: 20%; padding:0 5px} */
.bannerList .react-multi-carousel-list {
  margin-top: 15px;
}
.bannerList .react-multi-carousel-list .item {
  padding: 0 10px;
}
.bannerList ul li img {
  border-radius: 6px;
  height: 150px !important;
  width: 100% !important;
  object-fit: cover;
  box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.3);
}
.moviesListCarosals.top_week .react-multi-carousel-item > div {
  padding: 0 5px;
  border-radius: 15px;
  /* overflow: hidden; */
}
.moviesListCarosals .react-multi-carousel-list,
.bannerList .react-multi-carousel-list,
.latest_release .react-multi-carousel-list,
.team .react-multi-carousel-list {
  padding-left: 0;
}
.latest_release .btngroup span {
  padding: 0;
}
.latest_release a {
  color: var(--bs-white);
  display: block;
  position: relative;
}
.latest_release .btngroup span.btn {
  font-size: 60%;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 28px;
  padding: 0;
  display: block;
  text-align: center;
}
.latest_release .btngroup ul {
  width: 100%;
  float: left;
}
.latest_release .btngroup li {
  float: left;
  padding: 0 10px 0 0;
}
.latest_release .btngroup li:last-child {
  float: right;
  padding: 0;
}
.latest_release .site_text li {
  position: relative;
  padding: 0 15px 0 0;
}
.latest_release .site_text li::before {
  position: absolute;
  right: 6px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #999;
  top: 45%;
  content: "";
}
.latest_release .site_text li:last-child:before {
  display: none;
}
.top_week .custom-carousel-container .react-multi-carousel-list {
  overflow: visible;
}
.top_week .custom-carousel-container {
  padding: 0 0;
  /* overflow: hidden; */
}
.top_week .react-multi-carousel-item > div {
  padding: 0 10px;
  border-radius: 15px;
  overflow: hidden;
}
.top_week .react-multi-carousel-item > div .item video {
  padding: 0;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 1px -1px 20px 0px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 1px -1px 20px 0px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 1px -1px 20px 0px rgba(255, 255, 255, 0.3);
}
/* .top_week .react-multi-carousel-item > div:hover .item video {
  box-shadow: 1px -1px 30px 0px rgba(255,255,255,0.3);
  -webkit-box-shadow: 1px -1px 30px 0px rgba(255,255,255,0.3);
  -moz-box-shadow: 1px -1px 30px 0px rgba(255,255,255,0.3);
} */
.top_week .react-multiple-carousel__arrow--right {
  right: -6% !important;
}
.react-responsive-modal-modal.customModalClass {
  background: #000;
  max-width: 850px !important;
  width: 100% !important;
  position: relative;
}
.react-responsive-modal-modal.customModalClass video:focus {
  box-shadow: none !important;
}
.videoWrap {
  position: relative;
  z-index: 1;
}
.videoWrap::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 8%,
    rgba(0, 0, 0, 0.74) 100%
  );
  content: "";
}
.react-responsive-modal-modal.customModalClass .btngroup span a {
  font-size: 70%;
}
.react-responsive-modal-modal.customModalClass .movieInfo {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px;
  z-index: 2;
}
.react-responsive-modal-modal.customModalClass video {
  width: 100%;
  height: 100%;
}
.modalText {
  font-size: 85%;
}
.modalText li {
  padding-right: 10px;
}
.summary {
  font-size: 18px;
}
.rightText a {
  color: var(--bs-light);
  padding: 0 5px;
  font-weight: bold;
  font-style: italic;
}
.rightText span {
  color: var(--bs-gray);
}
.relatedbox {
  background: #111;
  color: var(--bs-light) !important;
  padding: 10px;
  width: 100%;
  height: 100%;
}
.relatedbox span {
  font-weight: bold;
  display: inline-block;
  margin: 10px 0;
}
.rightText p {
  margin-bottom: 0.3rem;
}
.react-responsive-modal-modal.customModalClass
  .react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  /* color: #fff; */
  z-index: 2;
  width: 35px;
  height: 35px;
  /* line-height: 100px; */
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0.8;
}
.latest_release .site_text {
  float: left;
}
.innerpage {
  padding: 60px 0 60px;
  color: #ccc;
  font-size: 90%;
}
.about h2 {
  margin-bottom: 15px;
  color: var(--bs-white);
}
.aboutpic {
  width: 100%;
  max-width: 350px;
  float: right;
  margin: 0 0 15px 30px;
  border-radius: 15px;
}
.about h4 i {
  color: var(--primary-color);
  margin-right: 10px;
}
.about h4 {
  margin: 0 0 20px;
}

.team .item img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.team .item span {
  display: block;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 0 15px;
}
.team .item span::before {
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  border: var(--primary-color) 1px solid;
  content: "";
}
.team .item a {
  color: var(--bs-white);
}
.team .react-multi-carousel-list {
  padding-top: 30px;
  padding-bottom: 50px;
}
.parallax {
  background: url(../images/login.jpg) top left no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 80px 0;
  position: relative;
  z-index: 1;
}
.parallax::before {
  position: absolute;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
}

.careerBox {
  border: var(--bs-white) 1px solid;
  padding: 30px;
  color: var(--bs-white);
  font-size: 80%;
  text-align: center;
  border-radius: 15px;
}
.careerBox:hover {
  color: var(--primary-color);
}
.team .react-multi-carousel-dot-list {
  display: flex !important;
  flex-direction: row;
  bottom: 0px;
}
.press {
  background: rgba(255, 255, 255, 0.15);
  padding: 30px;
  font-size: 80%;
  color: #ccc !important;
}
.press span {
  background: var(--primary-color);
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 6px 10px;
  color: var(--bs-dark);
}
.press h4 {
  margin: 0 0 10px 0;
}
.planBox {
  padding: 15px;
  border: #666 1px solid;
  border-radius: 6px;
}
.planHead {
  background: var(--primary-color);
}

.btnPrimary {
  background: #2daff4;
  color: #ffffff;
}
.btnPrimary:active:before,
.btnPrimary:focus:before,
.btnPrimary:hover:before {
  background: #0c98e2;
  color: #ffffff;
}
.btnPrimary:before {
  background: #0c98e2;
}

.btnOrange {
  background: #f5a623;
  color: #ffffff;
}
.btnOrange:active:before,
.btnOrange:focus:before,
.btnOrange:hover:before {
  background: #db8c0a;
  color: #ffffff;
}
.btnOrange:before {
  background: #db8c0a;
}

.btnPurple {
  background: #8a74b9;
  color: #ffffff;
}
.btnPurple:active:before,
.btnPurple:focus:before,
.btnPurple:hover:before {
  background: #6e54a6;
  color: #ffffff;
}
.btnPurple:before {
  background: #6e54a6;
}

.btnGreen {
  background: #4cb992;
  color: #ffffff;
}
.btnGreen:active:before,
.btnGreen:focus:before,
.btnGreen:hover:before {
  background: #3b9776;
  color: #ffffff;
}
.btnGreen:before {
  background: #3b9776;
}

.btnLightGreen {
  background: #7ed321;
  color: #ffffff;
}
.btnLightGreen:active:before,
.btnLightGreen:focus:before,
.btnLightGreen:hover:before {
  background: #64a71a;
  color: #ffffff;
}
.btnLightGreen:before {
  background: #64a71a;
}

.pricingStarter {
  border: 1px solid #ddd;
  color: #333;
  line-height: 24px;
  margin: 40px auto 15px;
  padding: 25px 0;
  text-align: center;
  width: 400px;
}
.pricingStarter h3 {
  color: #7ed321;
  margin: 5px 0 20px;
}
.pricingStarter ul {
  border-bottom: 1px solid #ddd;
  border-top: 3px solid #7ed321;
  list-style: none;
  margin: 0 0 25px;
  padding: 25px 10px;
}
.pricingStarter ul li {
  font-weight: 400;
  line-height: 1.8em;
}
.pricingStarter ul strong {
  font-weight: 600;
}
.pricingStarter span {
  display: block;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 10px;
}
.pricingStarter a.btnOutlined {
  background: #7ed321;
  color: #ffffff;
}
.pricingStarter a.btnOutlined::before {
  background: #64a71a;
}
.pricingStarter label {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

section.pricingPaid [class^="col-"],
section.pricingPaid [class^=" col-"] {
  padding-left: 7px;
  padding-right: 7px;
}
section.pricingPaid .pricingGrid {
  border-radius: 0;
  color: #9b9b9b;
  margin: 0 0 70px;
  padding: 0;
  text-align: left;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}
section.pricingPaid .pricingGrid .panel-heading {
  border-radius: 0;
  margin: 0;
  padding: 30px 25px;
  position: relative;
}
section.pricingPaid .pricingGrid .panel-heading h3 {
  line-height: 18px;
  margin: 0;
  text-align: left;
  text-transform: none;
}
section.pricingPaid .pricingGrid .panel-heading label {
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  padding: 5px 10px;
  position: absolute;
  right: 25px;
  text-transform: uppercase;
  top: 25px;
}
section.pricingPaid .pricingGrid .panel-body {
  border-width: 0 1px 1px;
  /* min-height: 215px; */
  padding: 30px 30px 0;
}
section.pricingPaid .pricingGrid .panel-body p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 0;
}
section.pricingPaid .pricingGrid .panel-body ul {
  margin: 0 25px;
  padding: 0;
  position: relative;
}
section.pricingPaid .pricingGrid .panel-body ul li {
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  padding: 0;
}
section.pricingPaid .pricingGrid .panel-body ul li::before {
  box-sizing: border-box;
  content: "•";
  display: inline-block;
  font-size: 18px;
  margin-left: -55px;
  padding-right: 15px;
  position: absolute;
  text-align: right;
  width: 58px;
}
section.pricingPaid .pricingGrid .panel-body .pricingInput {
  background-color: transparent;
  border: 1px solid #ddd;
  height: 35px;
  margin-right: 8px;
  padding: 3px 5px;
  width: 70px;
}
section.pricingPaid .pricingGrid .panel-footer {
  background: transparent;
  padding: 35px 25px 25px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate {
  margin-bottom: 35px;
  min-height: 90px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .price {
  color: #fff;
  display: block;
  font-size: 42px;
  font-weight: 600;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .price sup {
  font-size: 22px;
  font-weight: normal;
  top: -11px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .price small {
  color: #dbdbdb;
  font-size: 16px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .priceMonthly {
  display: none;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .pricePlans {
  border: 1px solid;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  padding: 0 15px;
  border-radius: 35px;
}
section.pricingPaid .pricingGrid .panel-footer a.btn {
  margin: 0;
}
section.pricingPaid .pricingGrid .panel-footer .btnMonthly {
  display: none;
}
section.pricingPaid .pricingViewToggler {
  margin: 60px auto 0;
  position: relative;
  width: 225px;
}
section.pricingPaid .pricingViewToggler .toggle-button {
  background-color: #fbfbfb;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin: 5px 0;
  position: relative;
  right: -30%;
  width: 80px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
section.pricingPaid .pricingViewToggler .toggle-button button {
  background-color: #2daff4;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  float: left;
  height: 30px;
  left: 8px;
  margin: -3px 0 0 -3px;
  outline: 0;
  position: absolute;
  top: 7px;
  transition: left 0.3s;
  width: 30px;
}
section.pricingPaid .pricingViewToggler .toggle-button-monthly button {
  border: none;
  height: 30px;
  left: 42px;
  margin: 0;
  top: 4px;
  width: 30px;
}
section.pricingPaid .pricingViewToggler span {
  color: #cccccc;
  display: block;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 8px;
}
section.pricingPaid .pricingViewToggler span.annual {
  left: 0;
}
section.pricingPaid .pricingViewToggler span.monthly {
  right: 0;
}
section.pricingPaid .pricingViewToggler span.active {
  color: #333;
}
section.pricingPaid .pricingBasic .panel-heading {
  background-color: #2daff4;
}
section.pricingPaid .pricingBasic .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingBasic .panel-heading label {
  background: #ffffff;
  color: #2daff4;
}
section.pricingPaid .pricingBasic .panel-body {
  border-top-color: #2daff4;
}
section.pricingPaid .pricingBasic .panel-footer .pricePlans {
  border-color: #2daff4;
  color: #2daff4;
}
section.pricingPaid .pricingPlus .panel-heading {
  background-color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingPlus .panel-heading label {
  background: #ffffff;
  color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-body {
  border-top-color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-footer .pricePlans {
  border-color: #f5a623;
  color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-heading {
  position: relative;
}
section.pricingPaid .pricingPremium .panel-heading {
  background-color: #8a74b9;
}
section.pricingPaid .pricingPremium .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingPremium .panel-heading label {
  background: #ffffff;
  color: #8a74b9;
}
section.pricingPaid .pricingPremium .panel-body {
  border-top-color: #8a74b9;
}
section.pricingPaid .pricingPremium .panel-footer .pricePlans {
  border-color: #8a74b9;
  color: #8a74b9;
}
section.pricingPaid .pricingEnterprise .panel-heading {
  background-color: #4cb992;
}
section.pricingPaid .pricingEnterprise .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingEnterprise .panel-heading label {
  background: #ffffff;
  color: #4cb992;
}
section.pricingPaid .pricingEnterprise .panel-body {
  border-top-color: #4cb992;
}
section.pricingPaid .pricingEnterprise .panel-footer .pricePlans {
  border-color: #4cb992;
  color: #4cb992;
}
section.pricingPaid .pricingEnterprise li:first-child {
  font-weight: 600 !important;
}
section.pricingPaid .pricingEnterprise li:first-child::before {
  display: none !important;
}
section.pricingPaid .pricingEnterprise .enterpriseAnnually .price > span,
section.pricingPaid .pricingEnterprise .enterpriseMonthly .price > span {
  margin: 0 -10px;
}

.moviesListCarosals .react-multi-carousel-item > div {
  transition: 0.3s ease-in-out;
  position: relative;
  border-radius: 15px;
  overflow: visible;
  padding-bottom: 30px;
  top: 0;
}
.moviesListCarosals.top_week .react-multi-carousel-item img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}
.moviesListCarosals .react-multi-carousel-item > div:hover img {
  transform: none;
  /* height: 200px; */
}
/* .moviesListCarosals .react-multi-carousel-item > div:hover {
  top: 60%;
  -moz-transform: scale(1.3) translateY(-40%);
  -webkit-transform: scale(1.3) translateY(-40%);
  -o-transform: scale(1.3) translateY(-40%);
  -ms-transform: scale(1.3) translateY(-40%);
  transform: scale(1.3) translateY(-40%);
  position: absolute;
} */
.moviesListCarosals .react-multi-carousel-item > div:hover .movie_content {
  display: block;
}
.moviesListCarosals .react-multi-carousel-item > div a {
  display: block;
}
.moviesListCarosals .react-multi-carousel-track {
  padding-top: 25px;
  padding-bottom: 0;
}
/* .latest_release {
  margin-top: 80px;
} */
.moviesListCarosals .react-multi-carousel-item:hover {
  z-index: 5;
}
.top_week .react-multi-carousel-item img {
  display: block;
  /* height: 200px; */
  object-fit: cover;
  border-radius: 6px;
}
.moviesListCarosals .btngroup span.btn {
  font-size: 60%;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 29px;
  padding: 0;
  display: inline-block;
  text-align: center;
  text-indent: 1px;
}
.moviesListCarosals .btngroup {
  margin-bottom: 10px !important;
}
.moviesListCarosals .btngroup ul {
  width: 100%;
  float: left;
}
.moviesListCarosals .btngroup li {
  float: left;
  padding: 0 10px 0 0;
}
.moviesListCarosals .btngroup li:last-child {
  float: right;
}
.moviesListCarosals .site_text li {
  position: relative;
  padding: 0 15px 0 0;
}
.moviesListCarosals .site_text li::before {
  position: absolute;
  right: 6px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #999;
  top: 45%;
  content: "";
}
.moviesListCarosals .site_text li:last-child:before {
  display: none;
}
.moviesListCarosals .movie_content {
  /* bottom: 0;
  padding: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: static;
  display: none;
  opacity: 1;
  visibility: visible; */
}
.moviesListCarosals .movie_content .site_text {
  font-size: 70%;
}

.moviesListCarosals .react-multi-carousel-list .item {
  padding: 0 5px;
}
.react-multi-carousel-list {
  overflow: visible !important;
}
.latest_release .item img {
  height: 250px;
}
.moviesListCarosals .react-multiple-carousel__arrow--right {
  right: -6% !important;
}
.moviesListCarosals .react-multiple-carousel__arrow--left {
  left: -6% !important;
}
.moviesListCarosals .react-multiple-carousel__arrow {
  width: 6% !important;
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 1) !important;
  height: 100% !important;
  border: none !important;
  z-index: 1 !important;
}

/* 1-8-2024 */
.moviesListCarosals .react-multi-carousel-track {
  margin: 0 -10px;
}
.moviesListCarosals .react-multi-carousel-list .item {
  position: relative;
}
.defaultPoster {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  display: block;
  box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 1px -1px 15px 0px rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  overflow: hidden;
}
.onHoverPoster {
  display: none;
  position: absolute;
  left: 0;
  top: 0%;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  animation: zoomIn 1s ease normal;
  box-shadow: 1px -1px 20px 0px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 1px -1px 20px 0px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 1px -1px 20px 0px rgba(255, 255, 255, 0.3);
  background: var(--theme-dark);
  border-radius: 6px;
  overflow: hidden;
}
.latest_release .onHoverPoster {
  top: -3%;
}
.onHoverPoster video {
  width: 100%;
  height: 150px;
}
.latest_release .onHoverPoster video {
  width: 100%;
  height: 250px;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.05, 0.05, 0.05);
    transform: scale3d(0.05, 0.05, 0.05);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.05, 0.05, 0.05);
    transform: scale3d(0.05, 0.05, 0.05);
  }
  50% {
    opacity: 1;
  }
}
.onHoverPoster img {
  border-radius: 6px 6px 0 0 !important;
}
.moviesListCarosals .react-multi-carousel-item:hover .defaultPoster,
.latest_release .react-multi-carousel-item:hover .defaultPoster {
  opacity: 0;
}
.top_week.moviesListCarosals .react-multi-carousel-item > div .item video {
  padding: 0 10px;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 1px -1px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px -1px 20px 0px rgba(0, 00, 0.3);
  -moz-box-shadow: 1px -1px 20px 0px rgba(0, 0, 0, 0.3);
}
.moviesListCarosals .react-multi-carousel-item:hover .onHoverPoster {
  display: block;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  box-shadow: 1px -1px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px -1px 20px 0px rgba(0, 00, 0.3);
  -moz-box-shadow: 1px -1px 20px 0px rgba(0, 0, 0, 0.3);
}

.latest_release .react-multi-carousel-item:hover .onHoverPoster {
  display: block;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  box-shadow: 1px -1px 20px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px -1px 20px 0px rgba(0, 00, 0.3);
  -moz-box-shadow: 1px -1px 20px 0px rgba(0, 0, 0, 0.3);
}

.original .react-multiple-carousel__arrow--left {
  left: 1% !important;
}
.original .react-multiple-carousel__arrow--right {
  right: 1% !important;
}
.videoContent {
  text-align: center;
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-shadow: 2px 7px 4px rgba(0, 0, 0, 0.3);
}
.original
  .react-multi-carousel-item.react-multi-carousel-item--active:hover
  .videoContent {
  opacity: 1;
}
.videoContent h3 {
  font-size: 60px;
  padding: 0 15%;
  margin-bottom: 25px;
}
.videoContent a {
  display: inline-block !important;
  max-width: 300px;
  border: #fff 1px solid;
  border-radius: 30px;
  margin: 0 auto;
  padding: 8px 30px;
}
.original .react-multi-carousel-dot-list {
  display: flex !important;
  bottom: -5%;
}
.original .react-multi-carousel-dot button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
  background-color: var(--primary-color);
  opacity: 1 !important;
}
.react-multi-carousel-dot--active button {
  background-color: var(--secondary-color) !important;
}
/* .original .react-multi-carousel-item.react-multi-carousel-item--active {width: 1000px !important;} */
.original .react-multi-carousel-list {
  padding-left: 0;
}

/* blog page */
.blogbox img {
  border-radius: 15px;
}
.blogContent {
  position: relative;
  border-radius: 0 0 15px 15px;
  padding: 30px;
  background: #2a2a2a;
  color: #ddd;
}
.blogContent h4 {
  text-transform: capitalize;
}
.blogContent::before {
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  height: 60px;
  background: url(../images/post-con-bg-dark.svg) repeat-x top left;
  content: "";
  background-size: 20px;
}
.blogContent p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  margin: 10px 0 15px;
  -webkit-box-orient: vertical;
}
.blogContent small {
  position: relative;
  font-size: 80%;
  padding-left: 50px;
  display: block;
  margin-top: 15px;
}
.blogContent small::before {
  position: absolute;
  left: 0;
  top: 50%;
  width: 30px;
  height: 1px;
  content: "";
  background: var(--secondary2-color);
}
.blogbox img {
  border-radius: 15px;
  height: 330px;
  width: 100%;
  object-fit: cover;
}
.blogDetails .info li a {
  color: #ddd;
  padding-right: 30px;
  text-transform: uppercase;
  font-size: 80%;
}
.blogDetails .info li a i {
  color: var(--primary-color);
  margin-right: 10px;
}
.blogDetails {
  color: #ddd;
  font-size: 90%;
}
.blogDetails h3 {
  text-transform: capitalize;
}
.blogDetails h5 {
  position: relative;
  padding: 0 0 0 40px;
  border-left: var(--secondary2-color) 3px solid;
  margin: 30px 0;
  font-weight: normal;
  font-style: italic;
}
.blogDetails h5 span {
  position: absolute;
  left: 15px;
  top: 0;
}
.blogDetails img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

/* faq page */
.faq .accordion-button::after {
  background-image: none !important;
  content: "\f4fe";
  font-family: bootstrap-icons !important;
  transition: all 0.5s;
  font-size: 30px;
}
.faq .accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "\f2ea";
  font-family: bootstrap-icons !important;
  top: 5px;
  right: -10px;
}
.faq .accordion-button::after {
  transition: all 0.5s;
}
.faq .accordion-button:not(.collapsed) {
  color: var(--primary-color);
  background-color: transparent;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.5);
}
.faq .accordion-item {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
  padding: 15px 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.faq .accordion-button {
  background-color: transparent;
  font-family: var(--body-font-medium);
  font-size: 22px;
  text-transform: capitalize;
  padding-left: 0;
  color: #fff;
}
.faq .accordion-button::after {
  top: -4px;
  position: relative;
}
.faq .accordion-body {
  color: #ddd;
  font-size: 80%;
}
.faq .accordion-body {
  padding-bottom: 0;
}

/* contact */
.contact {
  font-size: 80%;
  color: #ddd;
}
.contact h3 {
  color: #fff;
}
.contactIcons {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  color: var(--secondary-color);
  font-size: 22px;
  line-height: 60px;
  margin-bottom: 25px;
  z-index: 1;
}
.contactIcons::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: var(--primary-color) 1px solid;
  content: "";
  z-index: -1;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.contact a {
  color: #ddd;
}
.contact a:hover {
  color: var(--primary-color);
}
.contact textarea {
  height: 200px;
}
.contactIcons:hover::before {
  background: var(--primary-color);
}

/* feedback */
.feedBackBox {
  padding: 45px 25px 25px;
  background-color: #333;
  border-radius: 15px;
  font-size: 80%;
  color: #ddd;
  position: relative;
}
.feedBackBox img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.feedBackBox p {
  font-style: italic;
  font-weight: bold;
}
.feedBackBox strong {
  display: block;
  margin-bottom: 0px;
}
.quote {
  position: absolute;
  width: 100%;
  text-align: center;
  color: var(--primary-color);
  font-size: 10vw;
  opacity: 0.15;
  left: 0;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.feedback .badge {
  margin-left: 15px;
  color: var(--bs-dark);
  padding: 6px;
  font-size: 80%;
  margin-bottom: 10px;
}
.feedStore textarea {
  height: 150px !important;
}

/* staff */
.staff a {
  overflow: hidden;
  text-align: center;
  margin-top: 2rem;
  color: var(--bs-white);
}
.staff a img {
  border: var(--bs-white) 1px solid;
  height: 280px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 15px;
}

/* watchlist */
.watchlist {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border: var(--primary-color) 1px solid;
}
.watchlist div {
  padding: 0 0 0 15px;
  line-height: 1.1;
}
.watchlist h4 {
  font-family: "aladinregular";
  margin: 15px 0;
}
.watchlist .pics {
  width: 160px;
  flex: 0 0 160px;
}
.watchlist .user img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 2px;
  border: var(--primary-color) 1px solid;
}
.watchlist .user li {
  padding: 0 0 0 5px;
}
.watchlist .user {
  align-items: center;
  font-size: 70%;
}
.watchlist .user a {
  color: #ccc;
}

/* history */
.history.watchlist .pics {
  width: 110px;
  flex: 0 0 110px;
}
.history.watchlist div {
  flex-grow: 1;
  color: #ddd;
}
.history.watchlist div p {
  font-size: 75%;
  margin: 8px 0 0 0;
}
.history.watchlist h4 {
  font-family: "aladinregular";
  margin: 0 0 8px 0;
}

/* billing */
.billing {
  font-size: 85%;
  color: #ddd;
}
.billing .table th {
  color: #ddd;
}
.billing .table td {
  background: rgba(255, 255, 255, 1);
  vertical-align: middle;
  border-bottom: 3px solid #1a1213;
}
.billing .btn {
  padding: 8px 10px;
  font-size: 10px;
}
.billPlan {
  background: var(--secondary2-color);
  padding: 30px;
  color: var(--bs-white);
  border-radius: 15px;
}
.methodBox {
  background: var(--bs-white);
  padding: 10px 15px;
  color: #000;
}
.visa {
  width: 60px;
  flex: 0 0 60px;
  background: #fff;
  padding: 8px;
  display: inline-block;
}
.methodBox p {
  margin: 0;
  padding: 0 20px;
}
.visaText {
  padding: 0 15px;
  font-size: 18px;
}
.billing.faq .accordion-body {
  color: #ddd;
  font-size: 100%;
}
.activePlan strong {
  font-size: 22px;
  color: var(--secondary2-color);
}
.accordion table,
.accordion table .visaText {
  font-size: 90%;
}

/* notification */
.notificationBox div {
  flex-grow: 1;
  padding: 0 30px;
}
.notificationBox a {
  color: #999;
}
/* .notificationBox {font-size: 75%;  color: #ddd;} */
.notificationBox .badge {
  font-size: 12px;
}
.notificationBox h6 {
  margin: 10px 0;
}
.notificationBox {
  padding-bottom: 5px;
  border-bottom: 1px solid #666;
}
.notificationBox:last-child {
  border: none;
}
.dates {
  width: 25%;
  flex: 0 0 25%;
  opacity: 0.8;
  text-align: right;
}
.notification_list span {
  padding-right: 15px;
}
.notification_list span i {
  color: var(--bs-green);
  margin-right: 5px;
}
.notification_list {
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: #333 1px solid;
}
.notification_list h6 {
  margin-bottom: 8px;
}
.notification_list .btn {
  margin-right: 10px;
}
.notification_list.accordion-button:not(.collapsed) span i {
  color: var(--bs-white);
  margin-right: 5px;
}
.userPanel .user img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.userPanel {
  padding: 10px 15px;
  background: var(--bs-white);
  color: #000;
}
.userMail {
  flex-grow: 1;
  padding: 0 15px;
}
.billing .form-check-input {
  width: 4em;
  height: 2em;
}
.billing .form-check {
  border-bottom: #666 1px solid;
}
.billing .form-check i {
  margin-right: 6px;
}
.billing .form-check p {
  margin-bottom: 0;
}
.billing .accordion-body {
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

/* releases */
.releases {
  font-size: 85%;
  color: #ddd !important;
  display: block;
}
.releases img {
  margin: 10px 0;
  height: 280px;
  object-fit: cover;
  width: 100%;
}
.releases:hover {
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

/* plan page */
.press {
  background: rgba(255, 255, 255, 0.15);
  padding: 30px;
  font-size: 80%;
  color: #ccc !important;
}
.press span {
  background: var(--primary-color);
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 6px 10px;
  color: var(--bs-dark);
}
.press h4 {
  margin: 0 0 10px 0;
}
.planBox {
  padding: 15px;
  border: #666 1px solid;
  border-radius: 6px;
}
.planHead {
  background: var(--primary-color);
}

.btnPrimary {
  background: #2daff4;
  color: #ffffff;
}
.btnPrimary:active:before,
.btnPrimary:focus:before,
.btnPrimary:hover:before {
  background: #0c98e2;
  color: #ffffff;
}
.btnPrimary:before {
  background: #0c98e2;
}

.btnOrange {
  background: #f5a623;
  color: #ffffff;
}
.btnOrange:active:before,
.btnOrange:focus:before,
.btnOrange:hover:before {
  background: #db8c0a;
  color: #ffffff;
}
.btnOrange:before {
  background: #db8c0a;
}

.btnPurple {
  background: #8a74b9;
  color: #ffffff;
}
.btnPurple:active:before,
.btnPurple:focus:before,
.btnPurple:hover:before {
  background: #6e54a6;
  color: #ffffff;
}
.btnPurple:before {
  background: #6e54a6;
}

.btnGreen {
  background: #4cb992;
  color: #ffffff;
}
.btnGreen:active:before,
.btnGreen:focus:before,
.btnGreen:hover:before {
  background: #3b9776;
  color: #ffffff;
}
.btnGreen:before {
  background: #3b9776;
}

.btnLightGreen {
  background: #7ed321;
  color: #ffffff;
}
.btnLightGreen:active:before,
.btnLightGreen:focus:before,
.btnLightGreen:hover:before {
  background: #64a71a;
  color: #ffffff;
}
.btnLightGreen:before {
  background: #64a71a;
}

.pricingStarter {
  border: 1px solid #ddd;
  color: #333;
  line-height: 24px;
  margin: 40px auto 15px;
  padding: 25px 0;
  text-align: center;
  width: 400px;
}
.pricingStarter h3 {
  color: #7ed321;
  margin: 5px 0 20px;
}
.pricingStarter ul {
  border-bottom: 1px solid #ddd;
  border-top: 3px solid #7ed321;
  list-style: none;
  margin: 0 0 25px;
  padding: 25px 10px;
}
.pricingStarter ul li {
  font-weight: 400;
  line-height: 1.8em;
}
.pricingStarter ul strong {
  font-weight: 600;
}
.pricingStarter span {
  display: block;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 10px;
}
.pricingStarter a.btnOutlined {
  background: #7ed321;
  color: #ffffff;
}
.pricingStarter a.btnOutlined::before {
  background: #64a71a;
}
.pricingStarter label {
  color: #333;
  display: block;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

section.pricingPaid [class^="col-"],
section.pricingPaid [class^=" col-"] {
  padding-left: 7px;
  padding-right: 7px;
}
section.pricingPaid .pricingGrid {
  border-radius: 0;
  color: #9b9b9b;
  margin: 0 0 0;
  padding: 0;
  text-align: left;
  transition: all 0.2s;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}
section.pricingPaid .pricingGrid .panel-heading {
  border-radius: 0;
  margin: 0;
  padding: 30px 25px;
  position: relative;
}
section.pricingPaid .pricingGrid .panel-heading h3 {
  line-height: 18px;
  margin: 0;
  text-align: left;
  text-transform: none;
}
section.pricingPaid .pricingGrid .panel-heading label {
  border-radius: 3px;
  color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  padding: 5px 10px;
  position: absolute;
  right: 25px;
  text-transform: uppercase;
  top: 25px;
}
section.pricingPaid .pricingGrid .panel-body {
  border-width: 0 1px 1px;
  /* min-height: 215px; */
  padding: 30px 30px 0;
}
section.pricingPaid .pricingGrid .panel-body p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 0;
}
section.pricingPaid .pricingGrid .panel-body ul {
  margin: 0 25px;
  padding: 0;
  position: relative;
}
section.pricingPaid .pricingGrid .panel-body ul li {
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  list-style: none;
  padding: 0;
}
section.pricingPaid .pricingGrid .panel-body ul li::before {
  box-sizing: border-box;
  content: "•";
  display: inline-block;
  font-size: 18px;
  margin-left: -55px;
  padding-right: 15px;
  position: absolute;
  text-align: right;
  width: 58px;
}
section.pricingPaid .pricingGrid .panel-body .pricingInput {
  background-color: transparent;
  border: 1px solid #ddd;
  height: 35px;
  margin-right: 8px;
  padding: 3px 5px;
  width: 70px;
}
section.pricingPaid .pricingGrid .panel-footer {
  background: transparent;
  padding: 35px 25px 25px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate {
  margin-bottom: 35px;
  min-height: 90px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .price {
  color: #fff;
  display: block;
  font-size: 42px;
  font-weight: 600;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .price sup {
  font-size: 22px;
  font-weight: normal;
  top: -11px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .price small {
  color: #dbdbdb;
  font-size: 16px;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .priceMonthly {
  display: none;
}
section.pricingPaid .pricingGrid .panel-footer .pricingRate .pricePlans {
  border: 1px solid;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  padding: 0 15px;
  border-radius: 35px;
}
section.pricingPaid .pricingGrid .panel-footer a.btn {
  margin: 0;
}
section.pricingPaid .pricingGrid .panel-footer .btnMonthly {
  display: none;
}
section.pricingPaid .pricingViewToggler {
  margin: 60px auto 0;
  position: relative;
  width: 225px;
}
section.pricingPaid .pricingViewToggler .toggle-button {
  background-color: #fbfbfb;
  border: 1px solid #e2e2e2;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 40px;
  margin: 5px 0;
  position: relative;
  right: -30%;
  width: 80px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
section.pricingPaid .pricingViewToggler .toggle-button button {
  background-color: #2daff4;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  float: left;
  height: 30px;
  left: 8px;
  margin: -3px 0 0 -3px;
  outline: 0;
  position: absolute;
  top: 7px;
  transition: left 0.3s;
  width: 30px;
}
section.pricingPaid .pricingViewToggler .toggle-button-monthly button {
  border: none;
  height: 30px;
  left: 42px;
  margin: 0;
  top: 4px;
  width: 30px;
}
section.pricingPaid .pricingViewToggler span {
  color: #cccccc;
  display: block;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 8px;
}
section.pricingPaid .pricingViewToggler span.annual {
  left: 0;
}
section.pricingPaid .pricingViewToggler span.monthly {
  right: 0;
}
section.pricingPaid .pricingViewToggler span.active {
  color: #333;
}
section.pricingPaid .pricingBasic .panel-heading {
  background-color: #2daff4;
}
section.pricingPaid .pricingBasic .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingBasic .panel-heading label {
  background: #ffffff;
  color: #2daff4;
}
section.pricingPaid .pricingBasic .panel-body {
  border-top-color: #2daff4;
}
section.pricingPaid .pricingBasic .panel-footer .pricePlans {
  border-color: #2daff4;
  color: #2daff4;
}
section.pricingPaid .pricingPlus .panel-heading {
  background-color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingPlus .panel-heading label {
  background: #ffffff;
  color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-body {
  border-top-color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-footer .pricePlans {
  border-color: #f5a623;
  color: #f5a623;
}
section.pricingPaid .pricingPlus .panel-heading {
  position: relative;
}
section.pricingPaid .pricingPremium .panel-heading {
  background-color: #8a74b9;
}
section.pricingPaid .pricingPremium .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingPremium .panel-heading label {
  background: #ffffff;
  color: #8a74b9;
}
section.pricingPaid .pricingPremium .panel-body {
  border-top-color: #8a74b9;
}
section.pricingPaid .pricingPremium .panel-footer .pricePlans {
  border-color: #8a74b9;
  color: #8a74b9;
}
section.pricingPaid .pricingEnterprise .panel-heading {
  background-color: #4cb992;
}
section.pricingPaid .pricingEnterprise .panel-heading h3 {
  color: #ffffff;
}
section.pricingPaid .pricingEnterprise .panel-heading label {
  background: #ffffff;
  color: #4cb992;
}
section.pricingPaid .pricingEnterprise .panel-body {
  border-top-color: #4cb992;
}
section.pricingPaid .pricingEnterprise .panel-footer .pricePlans {
  border-color: #4cb992;
  color: #4cb992;
}
section.pricingPaid .pricingEnterprise li:first-child {
  font-weight: 600 !important;
}
section.pricingPaid .pricingEnterprise li:first-child::before {
  display: none !important;
}
section.pricingPaid .pricingEnterprise .enterpriseAnnually .price > span,
section.pricingPaid .pricingEnterprise .enterpriseMonthly .price > span {
  margin: 0 -10px;
}

/* help center */
.help {
  width: 100%;
}
.help .searchIcon {
  position: absolute;
  left: 20px;
  top: 10px;
}
.help .form-control {
  padding: 0 0 0 50px;
}
.helpCenter {
  font-size: 80%;
}
.helpCenter p {
  text-align: center;
  color: #ddd;
}
.helpCenter p a {
  color: #ddd;
  padding: 0 3px;
  text-decoration: underline;
}
.explore {
  margin-top: 35%;
}
.explore a {
  color: #ddd;
}
.topics li {
  margin: 15px 0;
  padding-left: 30px;
}
.topics li a {
  color: #ddd;
}
.topics li a:hover {
  color: var(--secondary2-color);
}
.topics li a i {
  margin: 0 6px 0 0;
}
.topics .accordion-body {
  padding: 0 0 0;
}

/* shop */
.shopLeft {
  color: #ddd;
  font-size: 90%;
}
.shopLeft li {
  padding: 10px 0;
  border-bottom: #333 1px solid;
}
.shopLeft ul {
  margin: 0 0 20px;
}
.shopLeft li:last-child {
  border: none;
}
.shopLeft hr {
  margin-bottom: 0;
}
.shopLeft p {
  margin-bottom: 0;
}
.price span {
  padding: 0 5px;
  width: 50%;
  flex: 0 0 50%;
  line-height: 45px;
}
.price span .form-control {
  width: calc(100% - 18px);
  display: inline-block;
  float: right;
}
.shopRight select {
  width: 250px;
}
.shopRight select option {
  background: rgba(0, 0, 0, 0.9);
  padding: 10px;
}
.stars {
  display: flex;
  justify-content: flex-start;
  margin: 8px 0px 5px;
}
.stars li {
  margin: 0 4px 0 0;
}
.stars li a {
  color: var(--primary-color);
}
.shopBox img {
}
.shopBox {
  font-size: 75%;
  color: #ddd;
  overflow: hidden;
  border-radius: 6px;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.shopBox:hover {
  box-shadow: 0px 2px 32px -8px rgba(251, 194, 35, 0.6);
  -webkit-box-shadow: 0px 2px 32px -8px rgba(251, 194, 35, 0.6);
  -moz-box-shadow: 0px 2px 32px -8px rgba(251, 194, 35, 0.6);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.shopBox h4 {
  margin-bottom: 5px;
}
.price {
  color: var(--primary-color);
  font-size: 14px;
  padding-right: 10px;
}
.cut {
  text-decoration: line-through;
  color: #999;
}
.shopContent {
  background: #131114;
  padding: 10px 15px 15px;
  position: relative;
}
.floatOption {
  position: absolute;
  left: 0;
  bottom: -60px;
  width: 100%;
  border-top: #333 1px solid;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.floatOption li {
  width: 33.333%;
  border-right: #333 1px solid;
}
.floatOption li:last-child {
  border: none;
}
.floatOption li a {
  text-align: center;
  display: block;
  padding: 6px 10px;
  color: var(--bs-light);
  text-align: center;
  background: #131114;
}
.floatOption li a:hover {
  background: var(--secondary-color);
}
.shopBox:hover .floatOption {
  bottom: 0;
}
.page-item.disabled .page-link {
  color: #999;
  background-color: transparent;
  border-color: #666;
}
.page-link {
  color: #999;
  background-color: transparent;
  border: 1px solid #666;
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-white);
  background-color: var(--secondary2-color);
  border-color: var(--secondary2-color);
}

/* cart */
.cart {
  font-size: 85%;
}
.cart table {
  color: #ddd;
  vertical-align: middle;
}
.productCart span {
  width: 90px;
  flex: 0 0 90px;
  margin-right: 10px;
}
.productCart span img {
  border-radius: 6px;
}
.cart table strong {
  color: var(--primary-color);
}
.cart table td {
  color: #ddd;
}
.cart table td p {
  margin-bottom: 0;
}
.quantity {
  width: 100px;
}
.cart textarea {
  height: 100px;
}
.subTotal span {
  font-size: 16px;
}
.subTotal span strong {
  font-size: 22px;
  margin-left: 20px;
}
.subTotal .btn {
  max-width: 250px;
  width: 100%;
}

/* checkout */
.checkout {
  font-size: 85%;
}
.checkout textarea {
  height: 150px;
}
.order {
  background: var(--primary-color);
  padding: 30px;
  border-radius: 6px;
  font-size: 85%;
  color: #333;
}
.checkout table {
  font-size: 85%;
  color: #333;
}
.checkout .order table td:last-child,
.checkout .order table th:last-child {
  text-align: right;
}
.order .table > :not(caption) > * > * {
  border-color: #333;
}
.order .btn {
  width: 100%;
}

/* orderDetails */
.orderDetails {
  font-size: 85%;
  color: #ddd;
}
.orderDetails table {
  color: #ddd;
}
.orderDetails table td:last-child,
.orderDetails table th:last-child {
  text-align: right;
}
.orderDetails .table > :not(caption) > * > * {
  border-color: #333;
}
.orderDetails .order p {
  margin-bottom: 8px;
}
.orderDetails .order p i {
  margin-right: 5px;
}

/* favorite */
.fav .relative span {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  background: var(--bs-white);
  color: var(--secondary2-color);
}
.releases span {
  position: relative;
}
.releases span small {
  position: absolute;
  right: -4px;
  top: -10px;
  display: block;
  line-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--secondary-color);
  text-align: center;
  color: var(--bs-white);
  font-size: 80%;
}

/* critics */
.critics {
  color: var(--bs-white);
  overflow: hidden;
  border-radius: 6px;
  font-size: 80%;
  background: #1a1213;
}
.critics:hover {
  color: var(--primary-color);
}
.critics h6 {
  font-size: 100%;
  margin: 5px 0;
}
.critics img {
  margin: 0 0 10px;
}
.critics > * {
  padding: 0 15px;
}
.critics > img {
  padding: 0;
}
.critics:hover {
  box-shadow: 0px 2px 32px -8px rgba(251, 194, 35, 0.6);
  -webkit-box-shadow: 0px 2px 32px -8px rgba(251, 194, 35, 0.6);
  -moz-box-shadow: 0px 2px 32px -8px rgba(251, 194, 35, 0.6);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.feedback .form-control {
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: var(--theme-dark) !important;
  background-color: #11010109 !important;
  background-clip: padding-box;
  border: 1px solid #333;
  border-radius: 6px;
  height: 45px;
  box-shadow: none;
}
.mostwatch span {
  width: 100px;
  flex: 0 0 100px;
  padding-right: 10px;
}
.mostwatch span img {
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}
.mostwatch {
  font-size: 70%;
  color: #ddd !important;
}

/* megamenu */
.megamenu {
  position: absolute;
  left: 0;
  top: calc(100% - 10px);
  width: 100%;
  background: var(--theme-dark);
  padding: 15px 0;
  border-top: #333 1px solid;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
header nav ul > li:hover .megamenu {
  opacity: 1;
  visibility: visible;
}
.megamenu ul {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
}
.megamenu li small {
  font-size: 70%;
  margin-bottom: 10px;
  display: block;
  color: #999;
}
.megamenu li a {
  padding: 0;
  text-align: left;
  color: #ddd !important;
  margin-bottom: 5px;
}
.megamenu li a h6 {
  margin-bottom: 15px;
}
.megamenu li a:hover {
  color: var(--bs-white) !important;
}
nav ul li.active .megamenu li a::after,
nav ul li .megamenu li a::after {
  display: none !important;
}
.megamenu li a {
  font-size: 70%;
  display: block;
}
.megamenu li a:hover {
  color: var(--primary-color) !important;
}
.megamenu ul ul li {
  width: 100%;
}

/* resources */
.resources h3 {
  margin-bottom: 15px;
}
.resources {
  font-size: 85%;
}
.resources img {
  border-radius: 6px;
}
.tools h3 {
  margin: 10px 0;
}
.tools a {
  color: var(--primary-color);
  text-decoration: underline;
}
.tools {
  border-radius: 6px;
}
.commonModal .modal-body {
  background: var(--theme-dark);
  color: var(--bs-white);
  padding: 0;
  position: relative;
}
.commonModal .modal-body .btn-close {
  position: absolute;
  right: 0;
  top: -39px;
  opacity: 1;
  z-index: 9;
  background: transparent;
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 30px;
}
.commonModal .modal-body video {
  height: 410px;
  width: 100%;
}
.interview {
  color: #ddd !important;
}
.interview:hover {
  color: var(--primary-color) !important;
}
.interview img {
  border-radius: 6px;
  margin-bottom: 10px;
}

/* forums */
.forums-menu li {
  margin-bottom: 24px;
}

.forums-menu li a {
  color: var(--bs-white);
}

.forums-menu li a:hover {
  color: var(--secondary-color);
}

.forums-menu li a span {
  margin-right: 19px;
}

.forums-tab .user-img {
  margin-right: 20px;
  width: 50px;
  flex: 0 0 50px;
}
.question-header {
  display: flex;
  font-size: 15px;
  margin-bottom: 15px;
}

.question-header .user-name {
  margin-right: 10px;
  color: var(--primary-color);
}

.question-header .badge {
  margin-right: 20px;
  font-size: 12px;
}

.post span {
  font-size: 11px;
}

.forums-tab .user-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  border: var(--primary-color) 1px solid;
  padding: 2px;
}

.published a {
  margin-right: 10px;
  font-size: 11px;
  margin-left: 3px;
  color: var(--primary-color);
}

.question-inner {
  display: flex;
  justify-content: space-between;
}

.question-wrp {
  margin-top: 15px;
  font-size: 15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.9;
  letter-spacing: 0px;
  color: #bfbfbf;
}

.question-inner h5 a {
  color: var(--bs-white);
}

.question-vote {
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-vote li i {
  color: var(--secondary-color);
}

.question-review {
  display: flex;
  align-items: center;
}

.question-review li {
  margin-right: 10px;
  font-size: 13px;
}

.like {
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 5px 8px;
  font-size: 16px;
}

.like i {
  color: var(--primary-color);
}

.comment-box {
  border: 1px solid var(--primary-color);
  padding: 2px 7px;
}

.comment-box i {
  color: var(--secondary-color);
  margin-right: 6px;
}

.comment-box a {
  color: var(--bs-white);
}

.forums-tab .btn {
  padding: 6px 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 10px;
  font-size: 13px;
}

.question-box {
  margin-bottom: 30px;
  padding: 22px 23px;
  background-color: #ffffff1a;
  border-left: 3px solid var(--secondary-color);
  border-radius: 8px;
}

.forums-tab .nav-tabs .nav-link.active {
  font-size: 16px;
}

.forums-tab .nav-tabs .nav-link {
  font-size: 16px;
}

.forums-tab .nav-tabs {
  border-bottom: transparent;
  margin-bottom: 25px;
}

.forums-menu {
  margin: 79px 0 0 0;
  background-color: #ffffff1a;
  border-radius: 8px;
  padding: 31px 21px;
}

.forums-sidebar i {
  margin-right: 18px;
  color: var(--secondary-color);
}

.sidebar-cart li {
  background-color: #ffffff21;
  margin-bottom: 24px;
  padding: 13px;
  border-radius: 8px;
}

.question-item a {
  display: flex;
  justify-content: space-between;
}

.question-item .author-img img {
  width: 36px !important;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.question-item .author-details {
  display: flex;
  flex-direction: column;
}

.question-item .author-name {
  color: var(--primary-color);
  font-size: 13px;
}

.question-item .author-img {
  margin-right: 10px;
  width: 36px;
  flex: 0 0 36px;
}

.question-item .content {
  color: var(--bs-white);
  font-size: 15px;
  font-weight: 600;
}

.question-item .author-comment_box i {
  font-size: 10px;
  margin-right: 5px;
}

.question-item .author-comment_box span {
  font-size: 11px;
  color: var(--bs-white);
  margin-right: 10px;
}

.popular-question.forums-sidebar {
  background-color: #ffffff1c;
  border-radius: 8px;
  padding: 20px;
  margin-top: 39px;
}

.question-item li {
  margin-bottom: 15px;
}

.top-member {
  background-color: #ffffff1c;
  border-radius: 8px;
  padding: 20px;
  margin-top: 26px;
}

.top-member_list a {
  justify-content: start;
}

.top-member_list img {
  width: 27px !important;
}

.top-member_list li {
  background-color: #ffffff1c;
  border-radius: 8px;
  padding: 12px;
  border-left: 3px solid var(--secondary-color);
}

.tag a {
  color: var(--bs-white);
  font-size: 12px;
  background-color: #ffffff2e;
  padding: 1px 5px;
  margin: 3px;
  border-radius: 5px;
}

.forums-details p {
  margin-top: 15px;
  font-size: 15px;
  line-height: 1.9;
  letter-spacing: 0px;
  color: #bfbfbf;
}

.forums-details .question-tag a {
  color: var(--bs-white);
  font-size: 10px;
  margin-right: 10px;
  border: 1px solid var(--primary-color);
  padding: 3px;
}

.question-bottom span a {
  font-size: 15px;
  color: var(--secondary-color);
}

.question-bottom ul li {
  margin-right: 10px;
}
.question-bottom .facebook a {
  color: #5d82d1;
}
.question-bottom .linkedin a {
  color: #238cc8;
}
.question-bottom .whatsapp a {
  color: #43d854;
}
.question-bottom .twitter a {
  color: #40bff5;
}
.share a {
  color: #ffffff8f;
}
.forums-details_tab h6 {
  color: var(--secondary-color);
  margin-bottom: 35px;
}
.forums-details_tab {
  padding: 25px 0;
}
.forums-details_tab .form-floating {
  margin: 25px 0;
}
.forums-details_tab .form-box {
  margin: 25px 0;
}
.forums-details_tab .btn {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  border-radius: 0;
  padding: 14px 16px;
}
.answers-tabs h4 {
  margin-bottom: 25px;
}
.forums-details_tab2 ul {
  justify-content: end;
}
.forums-details .details-menu {
  margin-bottom: 25px;
}
.forums-details .forums-menu {
  margin-top: 0;
}
/* end */

/* shop-details */
.details-menu a {
  margin-right: 23px;
  position: relative;
  color: var(--secondary-color);
  font-size: 20px;
}

.details-menu a:after {
  position: absolute;
  top: 13px;
  right: -17px;
  content: "";
  background-color: var(--secondary-color);
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.details-page-wrp {
  padding-top: 56px;
}

.product__type a {
  padding-left: 28px;
}

/* .qty-control .quantity-text {
  background-color: transparent;
  border: 1px solid var(--bs-white);
  color: var(--bs-white);
  text-align: center;
  padding:0 10px;
  float: left;

} */

.qty-control {
  padding: 0 10px;
  float: left;
}

.qty-control .form-control {
  margin: 0 10px;
}

.qty-control__reduce {
  position: absolute;
  top: 5px;
  left: 14px;
  cursor: pointer;
}

.qty-control__increase {
  position: absolute;
  top: 5px;
  right: 14px;
  cursor: pointer;
}

.qty-control {
  display: flex;
  align-items: baseline;
}

.qty-control-box {
  border: 1px solid #fff;
  /* width: 100%; */
  padding: 0 26px;
  width: 100%;
  height: 45px;
  max-width: 246px;
  text-align: center;
  margin-left: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.qty-control h5 {
  font-size: 18px;
  margin-bottom: 10px;
}

.product-stock {
  display: flex;
  align-items: baseline;
  margin: 10px 0;
}

.shop-button .details-button {
  width: 100%;
  background-color: transparent;
  color: var(--bs-white);
  border: 1px solid var(--bs-white);
  padding: 10px;
  max-width: 242px;
  margin-right: 18px;
  border-radius: 5px;
}

.shop-button {
  margin: 14px 0;
}
.shop-button .btn-sm {
  padding: 10px 15px;
  font-size: 12px;
}

.wishlist-button {
  color: var(--bs-white);
  padding-right: 29px;
  font-size: 16px;
}

.summary .price {
  font-size: 20px;
  font-weight: 600;
}

.wishlist-button span {
  padding-right: 8px;
}

.summary p {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0px;
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.summary .stars {
  margin: 10px 0;
  padding-right: 23px;
}

.product-stock .product-type {
  color: #099271;
  padding: 1px 9px;
  /* border-radius: 13px; */
  border: 1px solid;
}

.product-stock .product-type span {
  margin-left: 6px;
}

.product-compare {
  margin-bottom: 10px;
}

.product-type span {
  margin-left: 15px;
}

.product_title {
  display: flex;
  justify-content: space-between;
}

.product_title a {
  color: var(--secondary-color);
  border: 1px solid #e9303069;
  font-size: 20px;
  padding: 4px 10px;
  border-radius: 12px;
}

.product-compare i {
  margin-right: 8px;
}

.product-compare span {
  font-size: 16px;
  margin-right: 43px;
}

.product-thumbnails-wrapper img {
  width: auto;
  height: 96px;
  margin-right: 16px;
}

.product-thumbnails-wrapper.d-flex {
  margin-top: 24px;
}

.product-compare a:hover {
  color: var(--secondary-color);
}

.search-icon {
  position: absolute;
  top: 11px;
  left: 13px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  padding: 6px 13px;
}

.product-single__description h5 {
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.product-single__reviews-item {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 29px;
}

.product-single__reviews-item .customer-review .customer-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-single__reviews-item .customer-review .review-date {
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 10px;
}

.product-single__reviews-item .customer-review .review-text {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 21px;
}

.select-star-rating {
  margin: 19px 0;
  display: flex;
  align-items: baseline;
}

.select-star-rating .stars {
  padding-left: 10px;
}

.select-star-rating i {
  font-size: 13px;
}

.form-label-fixed .form-control {
  height: 55px;
}

#form-input-review {
  height: 200px !important;
}

.product-single .bride-buttonone {
  border-radius: 0;
  width: 100%;
  background: transparent;
  color: var(--bs-white);
}

.form-label-fixed .form-label {
  position: absolute;
  top: -17px;
  left: 1rem;
  margin: 0;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  background-color: #000000;
  color: #fff;
  z-index: 1;
  font-size: 12px;
}

.form-label-fixed {
  position: relative;
}

.single-product-box {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 14px;
}

.product-single_features {
  margin-top: 36px;
}

.form-label-chekbox {
  display: flex;
  gap: 11px;
  align-items: baseline;
}

/* end */

/* wishlist */
.product-wishlist_image img {
  display: block;
  max-width: 100px;
  border-radius: 6px;
}

.wishlist-table {
  font-size: 80%;
  margin-top: 60px;
}

.product-wishlist_image {
  display: inline-block;
}

.product-wishlist td {
  vertical-align: middle;
}

.product-wishlist th {
  padding: 15px 0 21px;
  font-size: 18px;
  text-align: center;
  color: var(--bs-white);
}
.table > :not(caption) > * > * {
  text-align: center;
  color: #fff;
  padding: 24px 0 16px;
}

.wishlist-table .btn-close-button {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  padding: 4px 10px;
  border-radius: 5px;
}

.wishlist-name {
  color: var(--bs-white);
}

.wishlist-name:hover {
  color: var(--secondary-color);
}

.wishlist-table .btn-secondary2 {
  border-radius: 5px;
}

.wishlist-icon_name {
  margin-right: 19px;
}
.wishlist-icon a {
  margin-right: 0px;
  margin-top: 0;
  border: 0;
  color: var(--secondary-color);
  font-size: 23px;
}
.wishlist-icon {
  margin-top: 31px;
}

/* award */
.award-circle span {
  display: flex;
  justify-content: center;
  background: red;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  align-items: center;
  font-size: 29px;
}
.award-content {
  text-align: end;
}
.award-content2 {
  text-align: start;
}
.award-circle {
  display: flex;
  justify-content: center;
  position: relative;
  max-width: 150px;
  width: 100%;
  margin: 0 auto;
}

.half-circle {
  width: 124px;
  height: 53px;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  border: 3px solid var(--primary-color);
  border-bottom: 0;
  position: absolute;
  rotate: -89deg;
  right: 64%;
  top: 32%;
  /* bottom: 0; */
  z-index: -1;
}

.half-circle::after {
  position: absolute;
  content: "";
  width: 124px;
  height: 26px;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  border: 3px solid var(--primary-color);
  border-bottom: 0;
  right: -3px;
  top: 48px;
  rotate: 179deg;
  z-index: 0;
}

.award-circle::after {
  position: absolute;
  content: "";
  left: -74px;
  top: 35px;
  border: 1px solid var(--primary-color);
  width: 0;
  height: 81px;
  rotate: 90deg;
}

.award-circle::before {
  position: absolute;
  content: "";
  left: -82%;
  top: 44%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid var(--primary-color);
  border-bottom: 10px solid transparent;
}

.award-circle2::after {
  position: absolute;
  content: "";
  right: -50%;
  left: unset;
  top: 19%;
  border: 1px solid var(--primary-color);
  width: 0;
  height: 81px;
  rotate: 90deg;
}

.award-circle2::before {
  position: absolute;
  content: "";
  right: -82%;
  left: unset;
  top: 39%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 10px solid var(--primary-color);
  border-right: unset;
  border-bottom: 10px solid transparent;
}

.award-content h3 {
  color: var(--secondary-color);
  font-family: "aladinregular";
}
.award-content2 h3 {
  color: var(--secondary-color);
  font-family: "aladinregular";
}
.award-content2 p {
  margin: 25px 0;
}

.award-content p {
  margin: 25px 0;
}

.half-circle2 {
  width: 124px;
  height: 53px;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  border: 3px solid var(--primary-color);
  border-bottom: 0;
  position: absolute;
  rotate: 90deg;
  left: 64%;
  top: 32%;
  /* bottom: 0; */
  z-index: -1;
}
.half-circle2::after {
  position: absolute;
  content: "";
  width: 124px;
  height: 26px;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  border: 3px solid var(--primary-color);
  border-bottom: 0;
  right: -3px;
  top: 48px;
  rotate: 179deg;
  z-index: 0;
}
.award-content strong {
  color: var(--primary-color);
}
.award-content2 strong {
  color: var(--primary-color);
}
/* end */

/* review */
.review-box img {
  border-radius: 0 20px;
  height: 120px;
  border: 5px solid var(--bs-white);
  /* width: 100%; */
  max-width: 120px;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: -63px;
}
.review-box {
  text-align: center;
  background-color: #ffffff1c;
  padding: 29px;
  border-radius: 20px 0;
  margin-top: 97px;
  position: relative;
}
.review-box p {
  color: #ffffffb8;
  font-size: 15px;
  padding: 25px 0;
  position: relative;
}
.review-box .coma {
  display: none;
}
.review-box .coma2 {
  color: #fbc2232b;
  position: absolute;
  bottom: -98px;
  font-size: 128px;
  right: 0;
}
.review-box h3 {
  color: var(--primary-color);
  font-family: "aladinregular";
}
.user-review_page {
  padding-top: 100px !important;
}

/* end */

.movieSidbar {
  background: #181820;
  height: auto;
  padding: 30px 15px;
  border-top: var(--primary-color) 2px solid;
  border-radius: 6px;
  position: sticky;
  top: 80px;
}
.movieSidbar h6 a {
  display: block;
}
.movieSidbar h6 small {
  float: right;
}
.movieSidbar .accordion-button {
  background-color: transparent;
  padding: 0px 5px;
  color: #fff;
  text-transform: capitalize;
}
.movieSidbar .accordion-button::after {
  background-color: var(--bs-light);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-size: 57%;
  padding: 10px;
  background-position: center;
}
.movieSidbar h6 {
  margin: 10px 5px 10px 15px;
}
.movieSidbar h6 a {
  text-decoration: underline;
}
.movieSidbar a {
  color: var(--bs-white);
}

.movie_list .movies {
  margin-top: 0;
  margin-bottom: 15px;
}
/* .movieSidbar .accordion-item { border:#333 1px solid} */
.movieSidbar .accordion-button {
  padding: 10px 15px;
  font-size: 18px;
  color: var(--primary-color);
}
.movieSidbar .accordion-body {
  padding: 0 15px;
  width: 100%;
  float: left;
}
.movieSidbar .accordion-body li {
  padding: 5px 0 5px 15px;
  border-bottom: #333 2px dotted;
  color: var(--secondary2-color);
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #999;
  cursor: pointer;
}
.movieSidbar .accordion-body li .form-check .badge {
  font-size: 14px;
}

.movieSidbar .accordion-body li a {
  color: var(--secondary2-color);
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  position: relative;
  display: block;
}
.movieSidbar .accordion-body li a::before {
  position: absolute;
  left: -5px;
  top: 10px;
  width: 10px;
  height: 1px;
  background: var(--primary-color);
  content: "";
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.movieSidbar .accordion-body li a:hover::before {
  width: 20px;
}
.movieSidbar .accordion-body li a:hover {
  color: var(--primary-color);
  padding-left: 30px;
}
.movieSidbar .accordion-body li:last-child {
  border-bottom: none;
}
.movieSidbar .accordion-body li .form-check-input[type="checkbox"] {
  display: none;
}
.movieSidbar .form-check label {
  cursor: pointer;
}
.movieSidbar
  .accordion-body
  .showCheckBox
  li
  .form-check-input[type="checkbox"] {
  display: block;
}
.movieSidbar .accordion-body .showCheckBox .form-check {
  padding-left: 25px;
  cursor: pointer;
}

.form-check small {
  float: right;
}
.form-check .badge {
  background: transparent;
  font-size: 10px;
  font-weight: bold;
  color: var(--secondary2-color);
  background: transparent !important;
  opacity: 0.8;
  /* border:var(--bs-info) 1px solid !important;  */
}
.secScroll {
  /* max-height:85vh; overflow-y: auto; overflow-x: hidden; padding-right: 15px;  */
  position: relative;
}
.movie_list .movies a img {
  height: 400px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color2);
}

/* PrivacyPolicy */
.innerpage h2 {
  margin-bottom: 30px;
}
.innerpage ul,
.innerpage ol {
  margin: 30px 0;
  list-style-type: none;
}
.innerpage ul li,
.innerpage ol li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
}
.innerpage ul li:before,
.innerpage ol li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\F26A";
  font-family: bootstrap-icons !important;
  color: var(--primary-color);
}
.innerpage h4 {
  color: var(--primary-color);
}
.bannerList .react-multi-carousel-list .item a {
  display: block;
}
.home_banner img {
  height: 700px;
  object-fit: cover;
}

.home_banner .btngroup {
  /* position: absolute;
  bottom: 10%; */
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
}
.home_banner .bannerList {
  margin: 0;
}

nav li i {
  font-size: 12px;
  margin-left: 5px;
  position: relative;
  top: -2px;
}
.forumsSidbar {
  height: auto;
  position: sticky;
  top: 80px;
}
.forums-details_tab textarea {
  height: 200px !important;
  padding: 15px;
}
.question-box a {
  color: var(--bs-white);
}
.floatContent img {
  margin-bottom: 50px;
}
.top_week.movieWeek .react-multi-carousel-item > div h3 {
  text-shadow: 2px 2px #5d64f6;
}
.top_week.movieWeek .react-multiple-carousel__arrow--left {
  left: calc(0% - 30px) !important;
  right: auto !important;
}
.top_week.movieWeek .react-multiple-carousel__arrow--right {
  right: calc(0% - 30px) !important;
}
.top_week.movieWeek .react-multi-carousel-item > div {
  padding: 0;
}
.item.floatContent {
  text-align: center;
}
.item.floatContent a {
  display: block;
  padding: 0 20px;
}

.home_banner video {
  width: 100% !important;
  height: 600px;
  position: relative;
}
.banner_infos_listing .col-sm-5.text-end span {
  margin-left: 15px;
  padding: 8px 15px;
  background: rgba(0, 0, 0, 0.5);
}
.banner_infos_listing .col-sm-5.text-end span:last-child {
  border-left: var(--bs-white) 4px solid;
}
.btmText {
  font-size: 70%;
  background: var(--secondary-color);
  width: calc(100% + 30px);
  position: relative;
  left: -15px;
  padding: 6px 10px;
  margin-top: 12px;
  float: left;
}
.onHoverPoster .btmText {
  font-size: 70%;
  background: var(--secondary-color);
  width: calc(100% + 30px) !important;
  position: relative;
  left: -15px;
  padding: 6px 10px;
  margin-top: 12px;
  float: left;
}
.btmText li {
  padding-right: 10px;
}
.btmText .text-success {
  color: var(--secondary2-color) !important;
}
.latest_release .movie_content {
  padding: 15px 15px 0;
}
.newreleasePart .item a {
  display: block;
}
.newreleasePart .item a:hover .text-light {
  color: var(--primary-color) !important;
}
.newreleasePart .item img {
  border-radius: 15px;
  height: 280px;
  object-fit: cover;
  width: 100%;
}
.useRs {
  position: relative;
}
.cusTomDropdown {
  position: absolute;
  width: 200px;
  top: calc(100% + 5px);
  right: 0;
  background: var(--bs-white);
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.cusTomDropdown::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid var(--bs-white);
  border-top: 0;
  position: absolute;
  bottom: 100%;
  right: 15px;
  content: "";
}
.cusTomDropdown li {
  width: 100%;
  float: left;
}
.cusTomDropdown li a {
  font-family: "robotoregular" !important;
  padding: 6px 20px;
  font-size: 13px !important;
  color: var(--bs-dark);
  font-weight: normal !important;
}
.cusTomDropdown li a i {
  margin-right: 6px;
  color: var(--secondary-color);
}
.useRs:hover .cusTomDropdown {
  opacity: 1;
  visibility: visible;
}
.relatedbox img {
  height: 150px;
  object-fit: cover;
  width: 100%;
}
.backButton {
  background: #ccc;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.becomebtn {
  background: transparent;
  border: 2px solid var(--primary-color);
}
.becomebtn:hover {
  background: var(--primary-color);
}
.customModalClass video[poster] {
  height: 300px !important;
  object-fit: cover;
}
.moveiwatch {
  top: 75px;
  position: absolute;
  z-index: 9999;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 34px;
  color: #000000;
  background: #36eeff;
}
.home_banner .tailreMove .slider__container {
  display: none !important;
}
.home_banner .tailreMove .genarelSettig {
  display: none;
}
.home_banner .tailreMove .timeSecr {
  display: none;
}
.home_banner .tailreMove .inner__controls {
  position: absolute;
  right: 0;
  width: auto;
  bottom: 9em;
  z-index: 99999;
  background: #9875f6;
  border-radius: 8px 0 0 8px;
}
.home_banner .tailreMove .playPuse {
  display: none;
}
.home_banner .tailreMove .volime {
  display: none;
}
.cartifict {
  background-color: var(--secondary-color);
  padding: 8px 15px !important;
  display: inline-block;
  border-radius: 10px;
}
.home_banner .inner__controls p {
  display: none;
}
.home_banner .control_Container {
  background-color: transparent;
}
/* Loader.css */
.loader-container {
  width: 100%;
  height: 4px;
  background-color: #f3f3f3;
  position: relative;
  overflow: hidden;
}

.loader {
  width: 100%;
  height: 100%;
  background-color: #3498db;
  position: absolute;
  left: -100%;
  animation: slide 2s infinite linear;
}

@keyframes slide {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
.welcome img {
  width: 80%;
  display: block;
  text-align: center;
  margin: auto;
}

.banner_btn.length_big {
  display: inline-block;
  text-align: center;
}
.react-responsive-modal-modal {
  width: 100% !important;
  margin: 0 !important;
}

.career {
  font-size: 20px;
}
.career p {
  margin: 30px 0;
}
.career img {
  border-radius: 6px;
}
.parallax {
  position: relative;
  z-index: 1;
  background: url(../images/bg-5.jpg) no-repeat center center;
  background-size: cover;
}
.parallax::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  content: "";
  z-index: -1;
}
.parallax .form-control {
  padding: 0 30px;
  font-size: 0.8rem;
  line-height: 1.5;
  color: var(--bs-white) !important;
  background-color: transparent !important;
  background-clip: padding-box;
  border: 1px solid #333;
  border-radius: 30px;
  height: 50px;
  border: var(--bs-white) 1px solid;
  box-shadow: none;
  text-transform: capitalize;
}
.parallax option {
  background: var(--theme-dark);
  text-transform: capitalize;
}
.parallax textarea {
  height: 200px !important;
  padding: 30px !important;
}
.mobileNav button {
  background: transparent;
  border: transparent;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: bold;
  padding: 15px 15px;
  color: var(--secondary2-color);
  border-bottom: #333 1px solid;
  width: 100%;
  text-align: left;
}
.mobileNav button:hover,
.mobileNav button:focus {
  color: var(--primary-color);
}
.closeBtn {
  font-size: 28px;
  text-align: right;
  padding-right: 14px;
  margin: 10px 0;
  color: var(--secondary-color);
}
.mobileNav ul li a {
  padding: 5px 30px;
  color: var(--secondary2-color);
  font-size: 14px;
}
.mobileNav ul li a:hover,
.mobileNav ul li a:focus {
  color: var(--secondary-color);
}
.AccordionChevron {
  transition: transform 300ms;
  transform: rotate(0deg);
  float: right;
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
}
.mobileNav button[data-state="open"] > .AccordionChevron {
  transform: rotate(180deg);
}
.mobileNav .becomebtn {
  padding: 6px 9px;
  font-size: 10px;
}
.pl-5 {
  padding-left: 0.8rem;
}
