@media screen and (max-width: 1400px) {
    body , .infos .btn-secondary2 {
        font-size: 14px;
    }
    header nav ul li a {
        padding: 0 10px;
    }
}

@media screen and (max-width: 1280px) {
    h1 {
        font-size: 40px; 
    }
    
    h2 {
        font-size: 35px; 
    }
    h3 {
        font-size: 28px;
    }
    .infos .btn-secondary2 {
        font-size: 12px;
    }
}
@media screen and (max-width: 1136px) {
    .home_slider .item img {
        height: 580px;
        object-fit: cover;
    }
    .caption_info {
        margin: 10px 0;
    }
    .rating_tags {
        margin: 20px 0;
    }
    /* .banner_btn {
        font-size:18px;
    } */
    .top_week .owl-theme .owl-nav, .video_slier .owl-theme .owl-nav {
        width: 100%;
        left: 0%;
    }
    .latest_release .row {
        min-height: 580px;
    }
    .btn {
        padding: 10px 20px 12px;
    }
    header {
        padding: 15px 0;
    }
    .infos .btn-secondary2 {
        font-size: 10px;
    }
    nav li i {
        font-size: 10px;
        top: -2px;
    }
    header nav ul li a {
        padding: 0 5px;
    }
    .latest_release .row {
        min-height: auto; padding-bottom: 50px;
    }
    .reviws_btn {flex-wrap: wrap;}
    .reviws_btn li{margin:0 10px 10px 0;}
    .caption_info {
        flex-wrap: wrap;
    }
    
}
@media screen and (max-width: 991px) {
    .btn {
        padding: 10px 14px 12px;
    }
    .footer_text {text-align: center;}
    footer .justify-content-end {
        justify-content: center!important;
    }
    .reviws_btn li {margin-bottom: 10px;}
    .top_week .owl-theme .owl-nav, .video_slier .owl-theme .owl-nav {
        width: 116%;
        left: -8%;
    }
    .downloads {
        padding: 50px 0;
        text-align: center;
    }
    header nav ul li a {
        padding: 0px 20px;
    }
    .infos li a {
        padding: 0 6px;
    }
    body {
        font-size: 16px;
    }
    .video_slier {
        padding: 30px 0;
    }
    .n_geographic {
        margin: 60px 0 0;
        height: 300px;
    }
    footer {
        padding: 50px 0 10px;
    }
    .footer_head {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .footer_text.mt-5 {
        margin-top: 1.5rem !important;
    }
    .week_content h3 {
        font-size: 16vw;
    }
    .tab-content>.tab-pane {
        display: block;
        opacity: 1;
      }
    .users .main_User {
        width: 150px;
    }
    .users .main_User img {
        height: 150px;
    }
    .video_title {
        font-size: 28px;
    }
    .movie_banner_pic {
        flex: 0 0 330px;
        padding-right: 30px;
    }
    /* .latest_release .react-multi-carousel-track {
        padding-top: 40px;
        padding-bottom: 40px;
    } */
    .latest_release .row {
        min-height: auto;
    }
    .movie_content {
        padding: 30px 8px;
    }
    .btn.btn_samll {
        width: 123px;
    }
    header {
        background: var(--theme-dark);
        padding: 15px 0 15px 0;
    }
    .hamburger {
        display: inline-block;
        top: 12px;
    }
    header .infos .becomebtn{display: none;}
    header nav ul {
        position: fixed;
        top: 0;
        width:100%;
        height:100%;
        background: var(--theme-dark);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 5%;
        right:-100% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        -ms-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        z-index: 9;
    }
    header nav.open ul {
        right:0 !important;
    }
    header nav ul li a {
        font-size: 8vw; margin-bottom: 10px;
    }
    .home_banner .tailreMove .inner__controls {
        bottom: 7em;
    }
    .caption_info {
        flex-wrap: wrap;
    }
}
@media screen and (max-width:767px) {
    .single_reviws .text-end {
        text-align: end !important;
        padding-top: 0;
    }
    .text-end {
        text-align: center !important;
        padding-top: 15px;
    }
    footer .mt-4 {
        margin-top: 1rem !important;margin-bottom: 1rem !important;
    }
    .banner_caption {
        text-align: center;
        padding: 25px 35px;
        background: rgba(0, 0, 0, 0.45);
        border-radius: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .caption_info , .rating_tags{
        justify-content: center;
    }
    .latest_release .owl-item:hover {
        -moz-transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
    }
    .latest_release .item img {
        width: 150px;
    }
    .btn.btn_samll {
        font-size: 94%;
        width: calc(100% - 58px);
    }
    .movie_content .caption_info, .movie_content .caption_info .badge {
        font-size: 82%;
    }
    .movie_content .site_text {
        font-size: 90%;
    }
    .owl-theme .owl-nav [class*="owl-"] {
        width: 40px !important;
        height: 40px !important;
        font-size: 14px !important;
        margin: 0 5px !important;
    }
    .top_week .owl-theme .owl-nav, .video_slier .owl-theme .owl-nav {
        width: 100%;
        left: 0;
    }
    .top_week .owl-item a{
        display: block;
    }
    .top_week .owl-theme .owl-nav, .video_slier .owl-theme .owl-nav {
        width: 100%;
        left: 0;
        transform: none;
        top: calc(100% + 10px);
        text-align: center;
    }
    .pt-5 {
        padding-top: 4rem !important;
    }
    .top_week .owl-carousel .owl-nav button.owl-next {
        float: none;
    }
    .video_slier .owl-nav {display: none;}
    .top_week.original {
        padding-bottom: 50px;
    }
    .tab-pane {
        padding-top: 0;
    }
    hr.mb-5 {
        margin-bottom: 1.5rem!important;
        margin-top: 1.5rem!important;
    }
    .tab_heading {
        padding-left: 40px;
        position: relative;
    }
    .tab_heading span {
        margin-right: 0;
        position: absolute;
        left: 0;
        top: 0;
    }
    .home_banner .btngroup {
        margin: 0 !important;
    }
    .home_banner .tailreMove .inner__controls {
        bottom: 20px;
        right: 0;
    }
    .banner_infos_listing {
        max-width: 270px;
        bottom: 20px;
        left: 10px;
        padding: 0;
    }
    .home_banner .banner_btn.btn-light {
        background: rgba(255, 255, 255, 0);
        color: var(--bs-light) !important;
        font-size: 0px !important;
        text-align: center;
        padding: 0 !important;
        width: 38px;
        height: 38px;
    }
    .home_banner .banner_btn.btn-light i {
        font-size: 16px;
        line-height: 36px;
        text-align: center;
        display: block;
    }
    
    .icon__btn svg {
        font-size: 1.2rem;
    }
    .banner_infos_listing .btn {
        padding: 8px 10px 11px;
        border-radius: 6px;
    }
    .cartifict {
        padding: 9px 10px !important;
        border-radius: 6px;
        top: 1px;
        position: relative;
    }
    .MuiSvgIcon-root {
        font-size: 1.3rem;
    }
    .banner_btn.btn-light {
        font-size: 12px;
    }
    .btngroup span {
        padding: 0 5px 0 0;
    }
    .movieDetails {
        position: static;
    }
    .length_big {
        max-width: 200px !important;
        width: 100%;
    }
    .movie_details_Banner .caption_info {
        margin: 5px;
        justify-content: flex-start;
    }
    .movieDetails {
        /* width: 100%; */
        padding: 20px 0;
    }
    .banner_btn a {
        font-size: 14px;
        padding: 10px 15px;
    }
    .about_part {
        padding: 20px 0;
    }
    footer {
        padding: 20px 0 10px;
    }
    .cast {overflow: hidden;}
    .react-multi-carousel-list {
        padding-left: 0;
    }
    .cast_text , .reviws .site_text {
        font-size: 100%; text-align: center;
    }
    .signup {
        margin-top:4rem;
    }
    .loginHeading {
        font-size: 22px;
    }
    .login .btn {margin-bottom: 10px;}
    .editProfile .tab-content {
        margin-top: 20px; border-radius: 30px; margin-bottom:30px;
    }
    .faq .accordion-body , .helpCenter , .contact{
        font-size: 100%;
    }
    .faq .accordion {margin-bottom:50px}
    .contact .justify-content-end {
        justify-content: center!important;
    }
    .progressBar {text-align: center; margin-bottom:30px}
    .editProfile.tab_main .nav-tabs .nav-link {
        font-size: 16px;
    }
    .movieListTable .table {width: 600px;}
    .movieListTable h5 {
        font-size: 18px;
    }
    .videoContent h3 {
        font-size: 5vw;
    }

}
@media screen and (max-width:736px) {
    .movie_details_Banner .col-sm-11.d-flex {
        display: flex!important;
        flex-direction: column;
        text-align: center;
    }
    .movie_banner_pic {
        flex: 0 0 330px;
        padding:0 30px;
        margin-bottom: 15px;
    }
    .latest_release .react-multi-carousel-item > div:hover {
        transform: none;
        border-color: var(--bs-light);
    }
    .latest_release .react-multi-carousel-track {
        padding-top: 40px;
        padding-bottom: 0;
    }
    ul.d-flex.reviws_btn.mb-5 {
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer_head {
        font-size:18px;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 25px;
    }
    .week_content h3 {
        font-size: 12vw;
    }
    .aboutVideo {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

      .react-multiple-carousel__arrow::before {
        font-size: 14px !important;}
    .react-multiple-carousel__arrow {
        width: 25px !important;
        height: 25px !important;
    }   
    .top_week.original {
        padding-bottom: 30px;
    }
    .bannerList {
        margin: 30px 0 0;
    }
    .style-module_simpleStarRating__nWUxf svg {width:25px !important; height:25px !important;}
    .style-module_tooltip__tKc3i {
        top: -7px;
    }
    .users .main_User img {
        height: 130px; width: 130px !important;
    }
    .home_banner .btngroup {
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 100%;
        padding-right: 2%;
        padding-left: 2%;
    }
    .top_week .react-multi-carousel-item > div {
        padding: 0;
    }
    .top_week.original .item div {
        padding: 15px;
    }

    
}
@media screen and (max-width:667px) {
    .downloads .btn {
        margin: 0 5px 10px;
    }
    .top_week a {display: block;}
    h1 {
        font-size: 23px;
    }
    h3 {
        font-size: 20px;
    }
    .movie_content .caption_info, .movie_content .caption_info .badge {
        font-size: 65%;
    }
    .joined {
        position: static;
        top: 31px;
        position: relative;
    }
    h2 {
        font-size: 28px;
    }
    .mb-4 {
        margin-bottom: 1rem!important;
    }
    .movies {
        margin-top:15px;
    }
    .users .btn {
        min-width: 126px;
    }
    .tab_heading {
        font-size: 22px;
    }
    body {
        font-size: 14px;
    }
    ul.d-flex.reviws_btn .btn {
        padding: 12px 15px;
        font-size: 12px;
    }
    header {
        background: var(--theme-dark);
        padding: 15px 10px 15px 0;
    }
    .aboutpic {
        max-width: 100%;
        margin: 0 0 20px 0;
    }
    .team.pt-5{padding-top: 0 !important;}
    .parallax {
        padding: 50px 0;
    }
    .careerBox {
        display: block;
        width:100%;
        margin: 0 auto;
    }
    .press {
        width: 100%;
    }
    .innerpage , .careerBox  {
        font-size: 100%;
    }
    .movieSidbar {
        position: static;
        margin-bottom: 30px;
    }
    .length_big {
        max-width: inherit !important;
        width: auto;
    }
    .innerBanner_img_wrap img , .innerBanner_img_wrap{
        height: 200px;
        object-fit: cover;
    }
    .users .main_User img {
        height: 100px;
        width: 100px !important;
    }
    .users {
        margin-top: -60px;
    }
    .mail {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
    }
    .user_heading {text-transform: capitalize;}
    .innerBanner, .movie_list {
        padding: 30px 0 0;
    }
    .faq .mb-5 {
        margin-bottom:1.5rem!important;
    }
    .faq .accordion-button {
        font-size: 18px !important;     padding:0.5rem 0rem;
    }
    .top_week.original .react-multi-carousel-item  {width: 100% !important;  flex: 0 0 100% !important}
    .home_banner video {
        width: 100% !important;
        height: 300px !important;
        position: relative;
        object-fit: cover;
        margin: 0 auto;
        display: block;
    }
    .player {
        width: 95% !important;
        margin: 0 auto;
        border: #333 1px solid;
        border-radius: 6px;
        height: auto !important;
        overflow: hidden;
    }
    .footer_head {
        font-size: 16px;
        margin-top: 15px;
    }
    .tags {
        font-size: 100%;
    }
    .cast_img {
        width: 100px !important;
        height: 100px;
    }
}
@media screen and (max-width:575px) {
    header {
        padding: 15px 25px 15px 0;
    }
    .footer_link {
        width:33.333%; float: left;
    }
    .cast .owl-theme .owl-nav {
        left: 0%;
        width: 100%;
        top: inherit;
        text-align: center;
    }
    .cast .owl-carousel .owl-nav button.owl-next {
        float:none;
    }
    .movie_details_Banner .caption_info {
        flex-wrap: wrap;
    }
    .home_banner .tailreMove .inner__controls {
        bottom: 10px;
        right: 10px;
    }
    .banner_infos_listing {
        max-width: 270px;
        bottom: 10px;
        left: 15px;
        padding: 0;
    }
    .videoContent h3 {
        font-size: 7vw;
        padding: 0 5%;
    }
    .latest_release .movie_content {
        padding: 8px 8px 0;
    }
}
@media screen and (max-width:480px) {
    h2 {
        font-size: 24px;
    }
    h5 {
        font-size: 20px;
    }
    .footer_link {
        width:50%; float: left;
    }
    
    .p-0 {
       padding: 0 15px !important;
    }
    .movie_content .caption_info, .movie_content .caption_info .badge {
        font-size: 80%;
    }

    .accordion-button {
        font-size:0.9rem;
    }
    .newreleasePart .item img {
        height: 200px;
    }
    .main_img {
        height: 200px;
    }
    .week_content h3 {
        font-size: 80px;
    }

}
@media screen and (max-width:375px) {
    .footer_link {
        width:100%; 
    }

}